import {useState} from "react";
import {postJSON} from "../../../lib/postJSON.js";
import {useNavigate} from "react-router-dom";
import {AppBar, Box, Button, TextField, Toolbar, Typography} from "@mui/material";
import Logo from "../../../images/power-logo-svg.svg";

export function LoginPage({loginCallback}) {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [uuid, setUuid] = useState("");
    const [method, setMethod] = useState("");
    const nav = useNavigate();

    function handleInputChange(e) {
        setEmail(e.target.value);
    }

    async function handleLogin(e) {
        if (!uuid) {
            const res = await postJSON("/api/admin/login", {email});

            if (res.uuid) {
                setUuid(res.uuid);
                setMethod(res.method);
            } else if (res.error) {
                alert(res.error);
            }
            setEmail("");
        } else {
            const res = await postJSON("/api/admin/login/verify", {uuid, code});

            if (res.message === "verified") {
                loginCallback();
                nav("/admin");
            } else if (res.error) {
                alert(res.error);
            }
            setCode("");
            setUuid("");
            setMethod("");
        }
    }

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>
                    <Box
                        component="img"
                        sx={{
                            height: "40px",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            userSelect: "none"
                        }}
                        align="center"
                        alt="Your logo."
                        src={Logo}
                        draggable={false}
                    />
                </Toolbar>
            </AppBar>
            {!uuid && <Box sx={{
                position: "absolute",
                width: "600px",
                textAlign: "center",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"
            }}>
                <Typography variant={"h2"} sx={{margin: 1, padding: 0}}>Admin login</Typography>
                <TextField onSubmit={handleLogin} value={email} onChange={handleInputChange}
                           onKeyDown={event => event.key === "Enter" ? handleLogin() : ""} label="Email"
                           variant="outlined" size="medium" sx={{width: '35ch'}}/>
                <br/>
                <Button onClick={handleLogin} variant="contained" disableElevation size="large"
                        sx={{transform: "scale(1.2)", mt: 2}}>Login</Button>
            </Box>}
            {uuid && <Box sx={{
                position: "absolute",
                width: "600px",
                textAlign: "center",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"
            }}>
                <Typography variant={"h4"} sx={{margin: 1, padding: 0}}>{method === "sms" ? "A verification code has been sent to your phone number" : "A verification code has been sent to your email"}</Typography>
                <TextField onSubmit={handleLogin} value={code} onChange={(e)=>setCode(e.target.value)}
                           onKeyDown={event => event.key === "Enter" ? handleLogin() : ""} label="Verification code"
                           variant="outlined" size="medium" sx={{width: '35ch'}}/>
                <br/>
                <Button onClick={handleLogin} variant="contained" disableElevation size="large"
                        sx={{transform: "scale(1.2)", mt: 2}}>Login</Button>
            </Box>}
        </div>
    );
}