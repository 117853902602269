import {BottomNavigation, BottomNavigationAction, Paper} from "@mui/material";
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export function LoginFooterComponent() {
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                /*value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}*/
                sx={{ backgroundColor: 'primary.main' }}
            >
                {/*<BottomNavigationAction sx={{color: "primary.contrastText"}} label="Recents" icon={<RestoreIcon />} />
                <BottomNavigationAction sx={{color: "primary.contrastText"}} label="Favorites" icon={<FavoriteIcon />} />
                <BottomNavigationAction sx={{color: "primary.contrastText"}} label="Nearby" icon={<LocationOnIcon />} />*/}
            </BottomNavigation>
        </Paper>
    );
}
