import {io} from "socket.io-client";

export function Socket() {
    const socket = io();

    socket.on("connect", () => {
        console.log("Socket connected");
    });
    socket.on("disconnect", () => {
        console.log("Socket disconnected");
        socket.removeAllListeners();
    });

    return socket;
}