import {
    Box,
    Button, Checkbox, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {getUsersFromEvent} from "../../../lib/getAPI.js";
import {ageFromDOB} from "../../../../../lib/ageFromDOB.js";
import {postSendCustomMessage} from "../../../lib/postAPI.js";

export function CustomMessageDialog({eventId, dialogOpen, setDialogOpen, preSelected}) {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (dialogOpen) {
            getUsers();
        }
    }, [dialogOpen]);

    async function getUsers() {
        setLoading(true);
        const res = (await getUsersFromEvent(eventId)).users;
        if (res) {
            const formattedUsers = res.map(u => {
                return {
                    ...u,
                    user_name: u.user_firstname + " " + u.user_lastname,
                    age: ageFromDOB(u.user_dob)
                }
            });
            setUsers(formattedUsers);
            if (preSelected && formattedUsers.find(u => u.user_id === preSelected.user_id)) {
                setSelectedUsers([preSelected.user_id]);
            }
        }
        setLoading(false);
    }

    async function sendCustomMessage() {
        if (message !== "") {
            setLoading(true);
            const usersToSend = selectedUsers.map(u => users.find(user => user.user_id === u).user_phone);
            await postSendCustomMessage({phoneNumbers: usersToSend, message});
            handleCloseDialog();
            setLoading(false);
        }
    }

    const handleChangeSelectedUsers = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedUsers(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function toggleAllSelected() {
        if (selectedUsers.length === users.filter(u => u.user_phone).length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(users.filter(u => u.user_phone).map(u => u.user_id));
        }
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    return (
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Send Custom Message</DialogTitle>
            <DialogContent>
                <DialogContentText>This message is sent to the user(s) through SMS, or email if the user does not have a registered phone number</DialogContentText>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="multiple-checkbox-label">Recipients</InputLabel>
                    <Select
                        labelId="multiple-checkbox-label"
                        multiple
                        value={selectedUsers}
                        onChange={handleChangeSelectedUsers}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={users.find((u) => u.user_id === value).user_name} />
                                ))}
                            </Box>
                        )}
                    >
                        <MenuItem disabled={users.length === 0} onClick={toggleAllSelected} sx={{
                            fontWeight: "bold"
                        }}>
                            <Checkbox checked={(selectedUsers.length === users.filter(u => u.user_phone).length)} />
                            <ListItemText primary={"All"} />
                        </MenuItem>
                        <Divider />
                        {users.map((u) => (
                            <MenuItem key={u.user_id} value={u.user_id} disabled={u.user_phone === ""}>
                                <Checkbox checked={selectedUsers.indexOf(u.user_id) > -1} />
                                <ListItemText primary={u.user_name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    label="Message"
                    type="text"
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={15}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={sendCustomMessage} disabled={loading || message==="" || selectedUsers.length === 0}>
                    Send
                </Button>
                <Button onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}