import {UploadComponent} from "../../../components/uploadComponent.jsx";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useState} from "react";
import {postUploadScheduleFile} from "../../../lib/postAPI.js";

export function UploadScheduleDialog({eventId, dialogOpen, setDialogOpen, refresh}) {
    const [scheduleFile, setScheduleFile] = useState(null);

    async function handleUploadFile() {
        if (scheduleFile !== null) {
            const formData = new FormData();
            formData.append("file", scheduleFile);
            const res = await postUploadScheduleFile(eventId, formData);
            if (res.error) {
                alert(res.error);
            } else if (res.message) {
                setDialogOpen(false);
                refresh();
            }
        }
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Upload schedule</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Uploading a schedule will overwrite any existing schedule.
                </DialogContentText>
                <Box sx={{"& > *": {display: "inline-block", mr: 1}, mt: 2}}>
                    <UploadComponent file={scheduleFile} setFile={setScheduleFile} exampleFilePath={"/api/admin/download/scheduleExample"} />
                    <Button disabled={scheduleFile === null} variant={"contained"} onClick={handleUploadFile}>Upload</Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}