import {useEffect, useState} from "react";
import {dateFormatWithLinesSwapped} from "../../../../lib/dateFormat.js";
import {Box, Grid, Typography} from "@mui/material";

export function MessageItemComponent({msg, prevMsg, isOwnMessage}) {
    const [dateAndTime, setDateAndTime] = useState(null);
    const shouldShowHeader = prevMsg === null || (prevMsg.user_email !== msg.user_email) || (new Date(msg.chat_message_date).getTime() - new Date(prevMsg.chat_message_date).getTime() > 1000 * 60 * 10);

    useEffect(() => {
        dateFunc(msg.chat_message_date);
    }, []);

    function dateFunc(date) {
        const d = new Date(date);

        const dt = d.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).substring(0, 5);
        const dd = dateFormatWithLinesSwapped(d).replaceAll("-", "/").substring(0, 5);
        const currDate = dateFormatWithLinesSwapped(new Date()).replaceAll("-", "/").substring(0, 5);
        if (currDate === dd) {
            setDateAndTime({dd: "", dt});
        } else {
            setDateAndTime({dd, dt});
        }
    }

    return (
        <>
            {dateAndTime && <Grid container spacing={1} sx={{p: 1, paddingTop: (shouldShowHeader ? 1.5 : 0), paddingBottom: 1.5, userSelect: "none", webkitUserSelect: "none"}}>
                {shouldShowHeader && <Grid item xs={12} sx={{boxSizing: "border-box", width: "100%"}}>
                    <Box sx={{display: "flex", alignItems: "center", color: "#fff"}}>
                        <Box sx={{flexGrow: 1, order: (isOwnMessage ? "2" : "1"), textAlign: (isOwnMessage ? "right" : "left")}}>
                            <Typography
                                variant="span" fontSize={"1rem"} sx={{
                                fontWeight: "bold",
                                mr: (isOwnMessage ? 0 : 1),
                                float: (isOwnMessage ? "right" : "left"),
                                order: (isOwnMessage ? "2" : "1")
                            }}>
                                {`${msg.user_firstname} ${msg.user_lastname}`}
                            </Typography>
                            <Typography variant="span" sx={{
                                fontSize: "0.8rem",
                                fontWeight: "normal",
                                mr: (isOwnMessage ? 1 : 0),
                                order: (isOwnMessage ? "1" : "2")
                            }}>{`${dateAndTime.dd} ${dateAndTime.dt}`}</Typography>
                        </Box>
                    </Box>
                </Grid>}
                <Grid item xs={12}>
                    <Typography variant="p" fontSize={"1.4rem"} sx={{backgroundColor: "#fff", border: "1px #ccc solid", borderRadius: "4px", padding: "10px", maxWidth: "60%", boxSizing: "border-box", float: (isOwnMessage ? "right" : "left"), whiteSpace: "normal", wordBreak: "break-word"}}>{msg.chat_message}</Typography>
                </Grid>
            </Grid>}
        </>
    );
}