import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import {postChangeUserStatus} from "../../../lib/postAPI.js";

export function DisinviteUserDialog({eventId, dialogOpen, setDialogOpen, user, refresh}) {
    function handleCloseDialog() {
        setDialogOpen(false);
    }

    async function changeUserInviteStatus() {
        const res = await postChangeUserStatus(user.user_id, {eventId, inviteStatus: false});
        if (res) {
            if (res.success) {
                handleCloseDialog();
                refresh();
            } else if (res.error) {
                alert(res.error);
            } else {
                alert("Something went wrong. Please try again later.");
            }
        } else {
            alert("Something went wrong. Please try again later.");
        }
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Disinvite user?</DialogTitle>
            <DialogContent>
                {user && <Typography sx={{mb: 1}}>{user.user_email}</Typography>}
                <DialogContentText>
                    Are you sure you want to disinvite user?
                </DialogContentText>
                <DialogContentText fontSize={"0.8rem"} fontStyle={"italic"}>
                    You can reinvite them later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={changeUserInviteStatus} color="primary">
                    Yes
                </Button>
                <Button onClick={handleCloseDialog} color="primary">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
}