import {UserHeaderComponent} from "../components/userHeaderComponent.jsx";
import {Typography} from "@mui/material";

export function FrontPage() {
    return (
        <div>
            <UserHeaderComponent />
            <Typography variant="h2" align="center">Frontpage</Typography>
        </div>
    );
}