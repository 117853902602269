import {useNavigate} from "react-router-dom";
import {AppBar, Box, Button, Toolbar} from "@mui/material";
import Logo from "../../../images/power-logo-svg.svg";

/*const pages = [{
        text: "Home", link: "/admin"
    }, {
        text: "View Events", link: "/admin/events"
}];*/
const pages = [{
    text: "View Events", link: "/admin/events"
}];

export function AdminHeaderComponent() {
    const nav = useNavigate();

    function handleCloseNavMenu(e) {
        nav(e.target.value);
    }

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>
                    <Box
                        component="img"
                        sx={{
                            height: "40px",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                            userSelect: "none"
                        }}
                        align="center"
                        alt="Your logo."
                        src={Logo}
                        draggable={false}
                    />
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) =>
                            <Button
                                key={page.text}
                                value={page.link}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block', paddingLeft: '20px', paddingRight: '20px', fontWeight: 'bold' }}
                            >
                                {page.text}
                            </Button>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );
}