import {Box, Card, CardContent, MenuItem, Select, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {getEventInfo, getEventSchedule} from "../lib/getAPI.js";
import {daysBetweenDates} from "../../../lib/dateValidation.js";
import {dateFormat, dateFormatWithLines} from "../../../lib/dateFormat.js";
import {EventContext} from "../../../routers/userRouter.jsx";
import {CenteredLoading} from "../../../components/centeredLoading.jsx";

export function SchedulePage() {
    const eventId = useContext(EventContext);
    const [schedule, setSchedule] = useState(null);
    const [event, setEvent] = useState(null);
    const [scheduleError, setScheduleError] = useState("");
    const [currDate, setCurrDate] = useState('');
    const [allGroups, setAllGroups] = useState([]);
    const [currGroup, setCurrGroup] = useState(null);

    useEffect(() => {
        if (eventId) {
            getSchedule();
        }
    }, [eventId]);

    async function getSchedule() {
        setScheduleError("");
        const selectedEvent = (await getEventInfo(eventId)).event;
        const schedule = await getEventSchedule(eventId);

        if (schedule.data && schedule.data.length > 0) {
            const scheduleFormatted = schedule.data.map((s) => {
                return {
                    ...s,
                    schedule_date: new Date(s.schedule_date),
                    schedule_start_time: s.schedule_start_time.slice(0, 5),
                    schedule_end_time: s.schedule_end_time.slice(0, 5)
                }
            }).sort((a, b) => {
                if (a.schedule_start_time < b.schedule_start_time) return -1;
                if (a.schedule_start_time > b.schedule_start_time) return 1;
                return 0;
            });

            selectedEvent.days = [];
            const amountOfDays = daysBetweenDates(selectedEvent.event_start_date, selectedEvent.event_end_date);
            const allGroups = [...new Set(scheduleFormatted.map(({group_id}) => group_id))];
            setCurrGroup(allGroups[0]);
            setAllGroups(allGroups);
            for (let i = 0; i <= amountOfDays; i++) {
                const currDate = new Date(selectedEvent.event_start_date);
                currDate.setDate(currDate.getDate()+i);
                selectedEvent.days.push({
                    day: i+1,
                    date: dateFormatWithLines(currDate),
                    schedule: [
                        ...scheduleFormatted.filter((s) => s.schedule_date.getTime() === currDate.getTime())
                    ]
                });
            }
            if (dateFormat(new Date()) >= dateFormat(new Date(selectedEvent.event_start_date)) && dateFormat(new Date()) <= dateFormat(new Date(selectedEvent.event_end_date))) {
                setCurrDate(dateFormatWithLines(new Date()));
            } else {
                setCurrDate(dateFormatWithLines(new Date(selectedEvent.event_start_date)));
            }
            setEvent(selectedEvent);

            setSchedule(scheduleFormatted);
        } else {
            if (schedule.error === "You do not have a group in this event") {
                setScheduleError("You do not have a group in this event");
            } else if (schedule.data && schedule.data.length === 0) {
                setScheduleError("Your group does not currently have a schedule for this event");
            } else {
                setScheduleError("Unknown error");
            }
            setSchedule([]);
        }
    }

    function handleDayChange(e) {
        setCurrDate(e.target.value);
    }
    function handleGroupChange(e) {
        setCurrGroup(e.target.value);
    }

    function ScheduleList() {
        return (
            <>
                {scheduleError !== "" ?
                    <Typography fontSize={"1.3rem"}>{scheduleError}</Typography> :
                    <ScheduleCards />}
            </>
        );
    }
    function ScheduleCards() {
        return (
            <>
                {event && currGroup && allGroups.length > 1 ?
                    <Select
                        value={currGroup}
                        size="small"
                        sx={{
                            width: "100%",
                            mb: 1
                        }}
                        onChange={handleGroupChange}
                    >
                        {allGroups.map((item) => {
                            return <MenuItem key={item} value={item}>Group {item}</MenuItem>;
                        })}
                    </Select> : ""
                    }
                {event && <Select
                    value={currDate}
                    size="small"
                    sx={{
                        width: "100%"
                    }}
                    onChange={handleDayChange}
                >
                    {event.days.map(({day, date}) => {
                        return <MenuItem key={day} value={date}>Day {day} ({date})</MenuItem>;
                    })}
                </Select>}
                {event && event.days && event.days.find(item => item.date === currDate).schedule.map(({schedule_id, schedule_start_time, schedule_end_time, schedule_item_name, group_id}) => {
                    if  (group_id === currGroup) {
                        return (
                            <Card key={schedule_id} elevation={2} sx={{
                                border: "1px #ccc solid",
                                mt: 1,
                                mb: 1
                            }}>
                                <CardContent sx={{paddingBottom: "16px !important"}}>
                                    <Typography fontSize={"1.5rem"}>{schedule_item_name}</Typography>
                                    <Typography fontSize={"0.8rem"}
                                                color="text.secondary">{schedule_start_time + " - " + schedule_end_time}</Typography>
                                </CardContent>
                            </Card>
                        );
                    } else {
                        return "";
                    }
                })}
            </>
        );
    }

    return (
        <div>
            <Box
                sx={{
                    m: 1
                }}
            >
                {eventId && schedule ?
                    <ScheduleList /> :
                    <CenteredLoading />}
            </Box>
        </div>
    );
}