import {ChatHeaderComponent} from "./components/chatHeaderComponent.jsx";
import {Chat} from "./chat.jsx";
import {useEffect, useState} from "react";
import {SeeMembers} from "./seeMembers.jsx";
import ChatBackground from "../../../images/Power_BG_700x1000mm.jpg";
import {Box} from "@mui/material";

export function ChatWindow({chatId, closeOverlay}) {
    const [currOption, setCurrOption] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        fetch(ChatBackground)
            .then(response => response.blob())
            .then(blob => {
                const reader = new FileReader();
                reader.onloadend = () => setImageSrc(reader.result);
                reader.readAsDataURL(blob);
            });
    }, []);

    useEffect(() => {
        setCurrOption(null);
    }, [chatId]);

    function selectOption(option) {
        setCurrOption(option);
    }

    return (
        <Box sx={{opacity: (chatId ? 1 : 0)}}>
            <ChatHeaderComponent chatId={chatId} closeOverlay={closeOverlay} selectOption={selectOption}/>
            {currOption === null && <Chat chatId={chatId} imgBG={imageSrc}/>}
            {currOption === 'seeMembers' && <SeeMembers chatId={chatId}/>}
        </Box>
    );
}