import {Button, Dialog, DialogActions, DialogTitle, DialogContent, TextField, Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {postSendCustomMessage} from "../../../lib/postAPI.js";

export function SMSDialog({dialogOpen, setDialogOpen, phoneNumber, messageContent}) {
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (dialogOpen) {
            setPhone(phoneNumber ? phoneNumber : "");
            setMessage(messageContent ? messageContent : "");
        }
    }, [dialogOpen]);

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    async function handleSendSMS() {
        if (phone.length < 6) {
            alert("Phone number is too short");
            return;
        } else if (isNaN(Number(phone.replaceAll("+", "")))) {
            alert("Phone number is not a valid number");
            return;
        } else if (!phone.includes("+") && phone.length !== 8) {
            alert("Non-norwegian numbers need to include the country code");
            return;
        }
        const res = await postSendCustomMessage({phoneNumbers: [phone], message});
        if (res) {
            if (res.success) {
                handleCloseDialog();
            } else if (res.error) {
                alert(res.error);
            }
        }
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>Send SMS</DialogTitle>
            <DialogContent>
                {<Box sx={{mt: 1}}>
                    <TextField label={"Phone number"} onChange={(e)=>setPhone(e.target.value)} />
                    <br/>
                    <br/>
                    <TextField multiline label={"Message content"} sx={{
                        width: "100%"
                    }} onChange={()=>setMessage(e.target.value)} value={message}></TextField>
                </Box>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSendSMS} color="primary">
                    Send
                </Button>
                <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}