import {
    Box,
    Button, Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    MenuItem,
    Paper,
    Select, Stack,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import AddIcon from '@mui/icons-material/Add';
import {taskItemTypes} from "../../../../../lib/taskTypes.js";
import {TaskItem} from "./taskItem.jsx";
import {postCreateTask} from "../../../lib/postAPI.js";

export function CreateTaskDialog({eventId, dialogOpen, setDialogOpen}) {
    const [taskLabel, setTaskLabel] = useState("");
    const [taskDescription, setTaskDescription] = useState("");
    const [keepContents, setKeepContents] = useState(false);
    const [taskItems, setTaskItems] = useState([]);

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    function handleTaskLabelChange(event) {
        setTaskLabel(event.target.value);
    }
    function handleTaskDescriptionChange(event) {
        setTaskDescription(event.target.value);
    }

    function addTaskItem() {
        setTaskItems((items) => {return [...items, {label: "", type: "INPUT", options: []}]});
    }

    async function handleSaveTask() {
        const task = {
            label: taskLabel,
            description: taskDescription,
            items: taskItems.map((i) => {
                if (i.type === taskItemTypes.SELECT) {
                    i.options = i.options.filter((o) => {
                        return o.label !== "";
                    });
                    if (i.options.length === 0) {
                        i.type = taskItemTypes.INPUT;
                    }
                }
                return {
                    ...i
                }
            })
        }

        if (task.label === "") {
            alert("Task label cannot be empty");
            return;
        }
        if (task.items.length === 0) {
            alert("Task must have at least one item");
            return;
        }
        if (task.items.filter((i) => {
            return i.label === ""
        }).length > 0) {
            alert("Task item descriptions cannot be empty");
            return;
        }

        const res = await postCreateTask(eventId, {task});

        if (res.success) {
            if (keepContents) {
                setTaskLabel("");
                setTaskDescription("");
            } else {
                setTaskLabel("");
                setTaskDescription("");
                setTaskItems([]);
            }
        } else {
            if (res.error) {
                alert(res.error);
                console.error(res.error);
            } else {
                alert("Error creating task");
            }
        }
    }

    return (
        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth={"md"} fullWidth={true}>
            <DialogTitle>Create Task</DialogTitle>
            <DialogContent>
                <Box>
                    <TextField
                        margin="dense"
                        label="Task Name"
                        type="text"
                        //defaultValue={taskLabel}
                        value={taskLabel}
                        sx={{mr: 1}}
                        onChange={handleTaskLabelChange}
                    />
                    <TextField
                        margin="dense"
                        label="Task Description"
                        type="text"
                        //defaultValue={taskDescription}
                        value={taskDescription}
                        onChange={handleTaskDescriptionChange}
                    />
                </Box>
                <FormControlLabel control={<Checkbox checked={keepContents} size="large" onChange={(e) => {setKeepContents((e.target.checked))}} />} label="Keep contents" sx={{userSelect: "none"}} />
                <br />
                <Button variant={"contained"} size={"large"} startIcon={<AddIcon />} sx={{mt: 1}} onClick={addTaskItem}>Add Task Item</Button>
                {taskItems.map((item, index) => (
                    <TaskItem key={index} item={item} />
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveTask}>Save</Button>
                <Button onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}