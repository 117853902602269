import {VendorHeaderComponent} from "./vendorHeaderComponent.jsx";
import {TasksList} from "./tasksList.jsx";

export function VendorPage() {
    return (
        <>
            <VendorHeaderComponent/>
            <TasksList/>
        </>
    );
}