import {getUser} from "./getAPI.js";

export async function loginCheck(setIsLoggedIn) {
    const res = await getUser();
    if (res.error) {
        setIsLoggedIn(false);
    } else if (res.user) {
        setIsLoggedIn(true);
    }
}
export async function loginCheckUser(setIsLoggedIn, setUser) {
    const res = await getUser();
    if (res.error) {
        setIsLoggedIn(false);
    } else if (res.user) {
        setUser(res.user);
        setIsLoggedIn(true);
    }
}