import React, {useEffect, useState} from "react";
import {taskItemTypes} from "../../../../../lib/taskTypes.js";
import {Button, MenuItem, Paper, Select, TextField} from "@mui/material";

export function TaskItem({item}) {
    const [itemType, setItemType] = useState(item.type);
    const [defaultItemType, setDefaultItemType] = useState(item.type);
    const [itemLabel, setItemLabel] = useState(item.label);
    const [itemOptions, setItemOptions] = useState([]);

    useEffect(() => {
        /*console.log(Object.keys(taskItemTypes));
        console.log(item);*/
    }, [item]);

    function handleTaskTypeChange(event) {
        item.type = event.target.value;
        setItemType(event.target.value);
    }

    function addSelectOption() {
        item.options.push({label: "", value: ""});
        setItemOptions((options) => {return [...options, ""]});
    }

    return (
        <Paper sx={{mt: 2, mb: 2, p: 1}} variant={"outlined"}>
            <Select
                size={"small"}
                defaultValue={defaultItemType}
                sx={{mb: 1, mt: 1, mr: 1}}
                onChange={handleTaskTypeChange}
            >
                {Object.keys(taskItemTypes).map(t => (
                    <MenuItem key={t} value={t}>{t}</MenuItem>
                ))}
                {/*<MenuItem key={t.value} value={t.value}>{t.label}</MenuItem>*/}
            </Select>
            <TextField
                margin={"dense"} label="Task item description" type="text" defaultValue={item.label} size={"small"}
                onChange={(e) => {item.label = e.target.value; setItemLabel(e.target.value)}}
            />
            {item.type === taskItemTypes.SELECT && (
                <>
                    <Button
                        variant={"contained"} sx={{display: "block", boxSizing: "border-box", mb: 2}}
                        onClick={addSelectOption}
                    >Add option</Button>
                    {item.options.map((o, i) => (
                        <TextField
                            key={i}
                            margin={"dense"}
                            label={"Option label "+(i+1)}
                            type="text"
                            defaultValue={o.label}
                            size={"small"}
                            sx={{mt: 1, display: "block"}}
                            onChange={(e) => {
                                item.options[i].label = e.target.value;
                                item.options[i].value = e.target.value.toLowerCase().replaceAll(" ", "_");
                                setItemOptions((options) => {return [...options]});
                            }}
                        />
                    ))}
                </>
            )}
        </Paper>
    );
}