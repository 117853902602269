import {Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useEffect, useState} from "react";
import {getEventSchedule} from "../../../lib/getAPI.js";
import {CenteredLoading} from "../../../../../components/centeredLoading.jsx";
import {ScheduleDisplay} from "./../scheduleDisplay.jsx";
import {dateFormatWithLinesSwapped} from "../../../../../lib/dateFormat.js";
import {UploadScheduleDialog} from "./uploadScheduleDialog.jsx";
import {CreateVendorDialog} from "./createVendorDialog.jsx";

export function ViewScheduleDialog({eventId, dialogOpen, setDialogOpen}) {
    const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
    const [createVendorDialogOpen, setCreateVendorDialogOpen] = useState(false);
    const [schedule, setSchedule] = useState(null);
    const [scheduleItems, setScheduleItems] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (dialogOpen) {
            getSchedule();
        }
    }, [dialogOpen]);

    async function getSchedule() {
        const res = await getEventSchedule(eventId);
        if (res) {
            const scheduleFormatted = res.schedule.map(item => {
                return {
                    ...item,
                    schedule_start_time: item.schedule_start_time.slice(0, 5),
                    schedule_end_time: item.schedule_end_time.slice(0, 5),
                    schedule_date: dateFormatWithLinesSwapped(new Date(item.schedule_date))
                }
            });
            const _scheduleItems = [...new Set(scheduleFormatted.map(item => item.schedule_plan))];
            setScheduleItems(_scheduleItems);
            setSchedule(scheduleFormatted);
            setLoading(false);
        } else {
            setSchedule(null);
            setLoading(false);
        }
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    function handleUploadSchedule() {
        setUploadDialogOpen(true);
    }

    function handleCreateVendor() {
        setCreateVendorDialogOpen(true);
    }

    return (
        <Box>
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth={"xl"} fullWidth={true}>
                <DialogTitle>Schedule</DialogTitle>
                <DialogContent>
                    <Box>
                        {loading ? <CenteredLoading/> : (
                            <>
                                {schedule === null ? (
                                    "Error loading schedule"
                                ) : (
                                    schedule.length > 0 ?
                                        <ScheduleDisplay schedule={schedule}/> : "No schedule uploaded"
                                )}
                            </>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions sx={{justifyContent: "space-between"}}>
                    <ButtonGroup>
                        <Button onClick={handleUploadSchedule} variant={"contained"}>
                            Upload
                        </Button>
                        <Button onClick={handleCreateVendor} variant={"contained"} disabled={scheduleItems.length === 0}>Create vendor</Button>
                    </ButtonGroup>
                    <Button onClick={handleCloseDialog}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <UploadScheduleDialog eventId={eventId} dialogOpen={uploadDialogOpen} setDialogOpen={setUploadDialogOpen} refresh={getSchedule} />
            <CreateVendorDialog eventId={eventId} dialogOpen={createVendorDialogOpen} setDialogOpen={setCreateVendorDialogOpen} />
        </Box>
    );
}