export async function postJSON(url, object, method = "POST") {
  const res = await fetch(url, {
    method,
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(object),
  });
  /*if (!res.ok) {
    throw new Error(`Failed to post ${res.status}: ${res.statusText}`);
  }*/
  if (res.headers.get("Content-Type").includes("application/json")) {
    try {
      return await res.json();
    } catch(e) {
      throw new Error(`Failed to post ${res.status}: ${res.statusText}`);
    }
  } else {
    return res.text();
  }
}
