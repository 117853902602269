import {Box, Button, Menu, MenuItem, Paper, Typography} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {useRef, useState} from "react";

export function UploadComponent({file, setFile, fileError, title, accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", exampleFilePath}) {
    const btnEl = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    function handleFileUpload(event) {
        const _file = event.target.files[0];
        if (_file) {
            setFile(_file);
        }
    }

    function handleContextMenu(e) {
        if (exampleFilePath) {
            e.preventDefault();
            setAnchorEl(btnEl.current);
        }
    }

    async function downloadExampleFile(e) {
        const res = await fetch(exampleFilePath);

        const data = await res.blob();
        const url = window.URL.createObjectURL(new Blob([data]));
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = res.headers.get("Content-Disposition").split("=")[1];
        anchor.click();

        window.URL.revokeObjectURL(url);
    }

    return (
        <Box>
            <Typography variant={"h4"}>{title}</Typography>
            <Button
                ref={btnEl}
                component="label"
                variant="outlined"
                startIcon={<UploadFileIcon />}
                sx={{maxWidth: "200px"}}
                onContextMenu={handleContextMenu}
            >
                <Typography variant={"span"} sx={{width: "100%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{file ? file.name : "Upload file"}</Typography>
                <input type="file" accept={accept} hidden onChange={handleFileUpload} />
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={()=>setAnchorEl(null)}>
                <MenuItem onClick={downloadExampleFile}>Download example</MenuItem>
            </Menu>
        </Box>
    );
}