import {
    Autocomplete,
    Box,
    Button, createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Switch,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {UploadComponent} from "../../../components/uploadComponent.jsx";
import {postAddUserToEvent, postCreateUser, postUploadUsersFile} from "../../../lib/postAPI.js";
import {getAllUsers, getUsersFromEvent} from "../../../lib/getAPI.js";
import {CenteredLoading} from "../../../../../components/centeredLoading.jsx";
import {DatePicker} from "@mui/x-date-pickers";
import {dateFormatWithLinesSwapped} from "../../../../../lib/dateFormat.js";

export function AddUsersDialog({eventId, dialogOpen, setDialogOpen, defaultExcelChecked, refresh}) {
    const [excelChecked, setExcelChecked] = useState(defaultExcelChecked);

    useEffect(() => {
        if (defaultExcelChecked !== null) {
            setExcelChecked(defaultExcelChecked);
        }
    }, [defaultExcelChecked]);

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    function UploadUsersComponent() {
        const [usersFile, setUsersFile] = useState(null);

        async function handleUploadFile() {
            if (usersFile !== null) {
                const formData = new FormData();
                formData.append("file", usersFile);
                const res = await postUploadUsersFile(eventId, formData);
                if (res.error) {
                    alert(res.error);
                } else if (res.message) {
                    setDialogOpen(false);
                    refresh();
                }
            }
        }

        return (
            <Box sx={{"& > *": {display: "inline-block", mr: 1}}}>
                <UploadComponent file={usersFile} setFile={setUsersFile} exampleFilePath={"/api/admin/download/usersExample"} />
                <Button disabled={usersFile === null} variant={"contained"} onClick={handleUploadFile}>Upload</Button>
            </Box>
        );
    }

    function AddUserComponent() {
        const [newUserEmail, setNewUserEmail] = useState("");
        const [users, setUsers] = useState(null);
        const [loading, setLoading] = useState(true);
        const [createNewUser, setCreateNewUser] = useState(false);
        const [createUserFirstname, setCreateUserFirstname] = useState("");
        const [createUserLastname, setCreateUserLastname] = useState("");
        const [createUserOffice, setCreateUserOffice] = useState("");
        const [createUserDepartment, setCreateUserDepartment] = useState("");
        const [createUserPhone, setCreateUserPhone] = useState("");
        const [createUserDOB, setCreateUserDOB] = useState(null);
        const filter = createFilterOptions();

        useEffect(() => {
            getUsers();
        }, []);

        async function getUsers() {
            setLoading(true);
            const res = await getAllUsers();
            if (res.users) {
                const eventUsers = await getUsersFromEvent(eventId);
                const notInvitedUsers = res.users.filter(u => !eventUsers.users.some(eu => eu.user_email === u.user_email));
                setUsers(notInvitedUsers);
            } else {
                alert("Error getting users");
            }
            setLoading(false);
        }

        async function handleAddUser() {
            if (createNewUser === true) {
                if (newUserEmail === "") {
                    alert("Email cannot be empty");
                    return;
                }
                if (createUserFirstname === "") {
                    alert("Firstname cannot be empty");
                    return;
                }
                if (createUserLastname === "") {
                    alert("Lastname cannot be empty");
                    return;
                }
                if (createUserOffice === "") {
                    alert("Office cannot be empty");
                    return;
                }
                if (createUserPhone === "") {
                    alert("Phone cannot be empty");
                    return;
                }
                if (createUserDOB === null) {
                    alert("Date of birth needs to be a valid date");
                    return;
                }
                const DOBFormatted = dateFormatWithLinesSwapped(createUserDOB.$d).replaceAll("-", ".");
                const sendObj = {
                    eventId,
                    group: 1,
                    email: newUserEmail,
                    firstname: createUserFirstname,
                    lastname: createUserLastname,
                    office: createUserOffice,
                    department: createUserDepartment,
                    phone: createUserPhone,
                    dob: DOBFormatted,
                }
                const res = await postCreateUser(sendObj);
                if (res.success) {
                    setDialogOpen(false);
                    refresh();
                } else if (res.error) {
                    alert(res.error);
                } else {
                    alert("Error creating user. Please try again later.");
                }
            } else {
                const res = await postAddUserToEvent(eventId, {email: newUserEmail});
                if (res.error === "User does not exist") {
                    setCreateNewUser(true);
                } else if (res.error) {
                    alert(res.error);
                } else if (res.message) {
                    setDialogOpen(false);
                    refresh();
                } else {
                    alert("Error adding user. Please try again later.");
                }
            }
        }

        return (
            <>
                {loading === true ? <CenteredLoading /> : (
                    <>
                        <Box>
                            <Autocomplete options={users.map(u => u.user_email)}
                                          sx={{width: "400px"}}
                                          label="User email"
                                          variant="outlined"
                                /*value={newUserEmail}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setNewUserEmail(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                        // Create a new value from the user input
                                        setNewUserEmail(newValue.inputValue);
                                    } else {
                                        setNewUserEmail(newValue);
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.title);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            title: `Add "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}*/
                                          freeSolo
                                          onKeyUp={(event) => {
                                              setNewUserEmail(event.target.value);
                                          }}
                                          onChange={(event, newValue) => {
                                              setNewUserEmail(newValue);
                                          }}
                                          renderInput={(params) => <TextField {...params} label="Email" />}
                            />
                            {createNewUser === true && (
                                <Box sx={{"& > *": {mt: "10px !important", display: "block !important"}}}>
                                    <TextField label="Firstname" onChange={e => setCreateUserFirstname(e.target.value)} />
                                    <TextField label="Lastname" onChange={e => setCreateUserLastname(e.target.value)} />
                                    <TextField label="Office" onChange={e => setCreateUserOffice(e.target.value)} />
                                    <TextField label="Department" onChange={e => setCreateUserDepartment(e.target.value)} />
                                    <TextField label="Phone" onChange={e => setCreateUserPhone(e.target.value)} />
                                    <DatePicker
                                        label="Date of birth"
                                        value={""}
                                        onChange={(newValue) => setCreateUserDOB(newValue)}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Button variant={"contained"} sx={{fontSize: "1.3rem"}} onClick={handleAddUser}>Add</Button>
                        </Box>
                    </>
                )}
            </>
        );
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>Add users</DialogTitle>
            <DialogContent >
                <FormControlLabel control={<Switch defaultChecked={defaultExcelChecked} color="powerCustom" onChange={() => setExcelChecked(!excelChecked)} />} label="Excel" />
                <Box sx={{mt: 1, mb: 1, display: "flex", justifyContent: "space-between"}}>
                    {excelChecked ? <UploadUsersComponent /> : <AddUserComponent />}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}