import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {postLogin} from "./lib/postAPI.js";

export function LoginPage({loginCallback}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const nav = useNavigate();

    console.log(searchParams.get("key"));

    useEffect(() => {
        if (searchParams.get("key")) {
            login();
        }
    }, [searchParams]);

    async function login() {
        const res = await postLogin({key: searchParams.get("key")});
        if (res) {
            if (res.message === "verified") {
                loginCallback();
                nav("/vendor");
            }
        }
    }

    return (
        <></>
    );
}