import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {postSendInviteMessages} from "../../../lib/postAPI.js";
import {useState} from "react";

export function ConfirmSendInvitationsDialog({eventId, dialogOpen, setDialogOpen, setSnackOpen, setSnackMessage, triggerEventUpdate}) {
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    async function sendInviteMessages() {
        setButtonsDisabled(true);
        const res = await postSendInviteMessages(eventId);
        if (res.success) {
            setSnackMessage("Invitation messages sent!");
            setSnackOpen(true);
        } else {
            if (res.error) {
                alert(res.error);
            }
            console.log(res);
        }
        handleCloseDialog();
        setButtonsDisabled(false);
        triggerEventUpdate();
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Send Invitations?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to send invitations to all users?
                </DialogContentText>
                <DialogContentText fontSize={"0.8rem"} fontStyle={"italic"}>
                    Will not send to users who have already received invitation.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={sendInviteMessages} color="primary" disabled={buttonsDisabled}>
                    Send
                </Button>
                <Button onClick={handleCloseDialog} color="primary" disabled={buttonsDisabled}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}