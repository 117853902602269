import {useEffect, useState} from "react";
import {
    Box,
    Divider,
    MenuItem,
    Select,
    Typography,
    Button
} from "@mui/material";
import {TaskItemDisplayInput} from "./taskItems/taskItemDisplayInput.jsx";
import {TaskItemDisplaySelect} from "./taskItems/taskItemDisplaySelect.jsx";

export function TasksDisplay({tasks}) {
    const [selectedTask, setSelectedTask] = useState(null);

    useEffect(() => {
        if (tasks) {
            setSelectedTask(tasks[0]);
        }
    }, [tasks]);

    function handleSelectedTaskChange(e) {
        const task = tasks.find((t) => t.task_id === e.target.value);
        setSelectedTask(task);
    }

    async function getTaskExcel() {
        const res = await fetch(`/api/admin/tasks/download/${selectedTask.task_id}`);
        const data = await res.blob();
        const url = window.URL.createObjectURL(new Blob([data]));
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = res.headers.get("Content-Disposition").split("=")[1];
        anchor.click();

        window.URL.revokeObjectURL(url);
    }

    return (
        <>
            {tasks && selectedTask && (
                <Box>
                    <Select
                        value={selectedTask.task_id}
                        onChange={handleSelectedTaskChange}
                        size={"small"}
                    >
                        {tasks.map(({task_id, task_label}) => {
                            return (
                                <MenuItem key={task_id} value={task_id}>{task_label}</MenuItem>
                            );
                        })}
                    </Select>
                    <Button sx={{float: "right"}} variant={"contained"} color={"primary"} onClick={getTaskExcel}>Download</Button>
                </Box>)}
            {selectedTask && <Typography sx={{color: "grey.600"}}>{selectedTask.task_description}</Typography>}
            {selectedTask && selectedTask.task_items.map((t, i) => {
                let tid;
                switch(t.task_item_answer_type) {
                    case "INPUT":
                        tid = <TaskItemDisplayInput taskItem={t}/>;
                        break;
                    case "SELECT":
                        tid = <TaskItemDisplaySelect taskItem={t} />;
                        break;
                }
                return (
                    <Box key={t.task_item_id}>
                        {i !== 0 ? <Divider/> : null}
                        {tid}
                    </Box>
                );
            })}
        </>
    );
}