import {Box, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ScheduleDisplayGrid} from "./scheduleDisplayGrid.jsx";

export function ScheduleDisplay({schedule}) {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateText, setSelectedDateText] = useState(null);
    const [daysGrouped, setDaysGrouped] = useState(null);
    const [days, setDays] = useState(null);

    useEffect(() => {
        const daysGrouped = schedule.reduce((result, object) => {
            const group = object.schedule_date;
            if (!result[group]) {
                result[group] = [];
            }
            result[group].push(object);
            return result;
        }, {});
        const days = Object.keys(daysGrouped).sort((a, b) => new Date(a) - new Date(b));
        setDaysGrouped(daysGrouped);
        setDays(days);
        setSelectedDate(daysGrouped[days[0]]);
        setSelectedDateText(days[0]);
    }, [schedule]);

    function handleChangeDate(event) {
        setSelectedDate(daysGrouped[event.target.value]);
        setSelectedDateText(event.target.value);
    }



    return (
        <Box>
            {selectedDateText && <Select
                size={"small"}
                value={selectedDateText}
                sx={{mb: 1, mt: 1, float: "right"}}
                onChange={handleChangeDate}
            >
                {days && days.map((d, i) => {
                    return (
                        <MenuItem key={d} value={d}>Day {i+1} ({d})</MenuItem>
                    )
                })}
            </Select>}
            {selectedDate && <ScheduleDisplayGrid selectedDate={selectedDate} />}
        </Box>
    );
}