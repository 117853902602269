import {CenteredLoading} from "../../components/centeredLoading.jsx";
import {Box, Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

function compare(a, b) {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
}

export function TasksList() {
    const [user, setUser] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allCompleted, setAllCompleted] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        getTasks();
    }, []);

    async function getTasks() {
        setLoading(true);
        const resU = await (await fetch("/api/vendor/userInfo"))?.json();
        if (resU?.user) {
            setUser(resU.user);
        }
        const res = await (await fetch("/api/vendor/tasks"))?.json();
        if (res?.tasks) {
            const tasksFormatted = res.tasks.filter(t => t.vendor_task_id === null).map(t => {
                return {
                    ...t,
                    schedule_start_time: t.schedule_start_time.substring(0, 5),
                    schedule_end_time: t.schedule_end_time.substring(0, 5)
                }
            }).sort((a, b) => {
                const aDate = new Date(a.schedule_date);
                const bDate = new Date(b.schedule_date);

                const dateComparison = compare(aDate, bDate);
                if (dateComparison !== 0) {
                    return dateComparison;
                }

                const startTimeComparison = a.schedule_start_time.localeCompare(b.schedule_start_time);
                if (startTimeComparison !== 0) {
                    return startTimeComparison;
                }

                return a.schedule_end_time.localeCompare(b.schedule_end_time);
            });
            setTasks(tasksFormatted);
        }
        setLoading(false);
    }

    function handleTaskClick(taskId) {
        if (taskId) {
            nav("/vendor/task/"+taskId);
        }
    }

    return (
        <>
            {!loading && <Typography variant={"h3"} sx={{textAlign: "center", margin: "15px 0 0 0"}}>{user.schedule_item_name}</Typography>}
            <Box sx={{padding: 3}}>
                {loading ? <CenteredLoading /> : (tasks.map(t => (!t.completed &&
                    <Card key={t.schedule_id} elevation={4} sx={{mb: 2}}>
                        <CardContent>
                            <Typography sx={{fontSize: 24}}>
                                {`Group ${t.group_id}`}
                            </Typography>
                            <Typography sx={{fontSize: 14, color: "secondary.main"}}>
                                {`${t.schedule_start_time} - ${t.schedule_end_time} (${new Date(t.schedule_date).toLocaleDateString("en-GB", {day: "2-digit", month: "2-digit"}).replaceAll("/", ".")})`}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="medium" variant={"contained"} onClick={()=>{handleTaskClick(t.schedule_id)}}>Do Task</Button>
                        </CardActions>
                    </Card>)))}
                {allCompleted && <Typography sx={{fontSize: 24, width: "100%", textAlign: "center"}}>No available tasks</Typography>}
            </Box>
        </>
    );
}