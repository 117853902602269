import {useParams} from "react-router-dom";
import {
    Alert, Box,
    Button, ButtonBase, ButtonGroup,
    Paper, Snackbar,
    Stack, Typography,
} from "@mui/material";
import {useEffect, useState} from "react";
import {getEvent, getEventAllInfo} from "../lib/getAPI.js";
import {CenteredLoading} from "../../../components/centeredLoading";
import {EventInfoDialog} from "./components/dialogs/eventInfoDialog.jsx";
import {EventUsersDialog} from "./components/dialogs/eventUsersDialog.jsx";
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import {CustomMessageDialog} from "./components/dialogs/customMessageDialog.jsx";
import {CreateTaskDialog} from "./components/dialogs/createTaskDialog.jsx";
import {ViewTasksDialog} from "./components/dialogs/viewTasksDialog.jsx";
import {VendorTasksDialog} from "./components/dialogs/vendorTasksDialog.jsx";
import {ConfirmSendInvitationsDialog} from "./components/dialogs/confirmSendInvitationsDialog.jsx";
import {ViewScheduleDialog} from "./components/dialogs/viewScheduleDialog.jsx";
import {dateFormat} from "../../../lib/dateFormat.js";
import EventNoteIcon from '@mui/icons-material/EventNote';

export function ViewEventPage() {
    const {event_id} = useParams();
    const [event, setEvent] = useState(null);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const [usersDialogOpen, setUsersDialogOpen] = useState(false);
    const [customMessageDialogOpen, setCustomMessageDialogOpen] = useState(false);
    const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false);
    const [viewTaskDialogOpen, setViewTaskDialogOpen] = useState(false);
    const [confirmSendInvitationsDialogOpen, setConfirmSendInvitationsDialogOpen] = useState(false);
    const [viewScheduleDialogOpen, setViewScheduleDialogOpen] = useState(false);
    const [vendorTasksDialogOpen, setVendorTasksDialogOpen] = useState(false);
    const [isActiveEvent, setIsActiveEvent] = useState(false);
    const [isFinishedEvent, setIsFinishedEvent] = useState(false);
    const [eventUsersScanned, setEventUsersScanned] = useState(0);

    const [customMessageDialogPreSelected, setCustomMessageDialogPreSelected] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");

    useEffect(() => {
        getEventInfo();
    }, []);

    async function triggerEventUpdate() {
        await getEventInfo();
    }

    async function getEventInfo() {
        const res = (await getEventAllInfo(event_id)).event
        if (res) {
            setEvent(res);
            if (res.users.length > 0) { // Count users scanned in today
                const today = dateFormat(new Date());
                for (let i = 0; i < res.users[0].days.length; i++) {
                    if (dateFormat(new Date(res.users[0].days[i].date)) === today) {
                        let counter = 0;
                        for (let j = 0; j < res.users.length; j++) {
                            if (res.users[j].days[i].scanned) {
                                counter++;
                            }
                        }
                        setEventUsersScanned(counter);
                        break;
                    }
                }
            }


            // Check if event is active
            const now = dateFormat(new Date());
            const eEnd = dateFormat(new Date(res.event_end_date));
            const eStart = dateFormat(new Date(res.event_start_date));
            if (now <= eEnd) {
                setIsFinishedEvent(false);
                if (now >= eStart) {
                    setIsActiveEvent(true);
                } else {
                    setIsActiveEvent(false);
                }
            } else {
                setIsFinishedEvent(true);
                setIsActiveEvent(false);
            }
        }
    }

    function activateInfo() {
        setInfoDialogOpen(true);
    }
    function activateUsers() {
        setUsersDialogOpen(true);
    }
    function activateCustomMessage() {
        setCustomMessageDialogPreSelected(null);
        setCustomMessageDialogOpen(true);
    }
    function activateCreateTask() {
        setCreateTaskDialogOpen(true);
    }
    function activateViewTasks() {
        setViewTaskDialogOpen(true);
    }
    function activateSendInvites() {
        setConfirmSendInvitationsDialogOpen(true);
    }
    function activateViewSchedule() {
        setViewScheduleDialogOpen(true);
    }
    function activateVendorTasks() {
        setVendorTasksDialogOpen(true);
    }

    function smsDialogOpenWithPre(user=null) {
        if (user) {
            setCustomMessageDialogPreSelected(user);
            setCustomMessageDialogOpen(true);
        }
    }

    function handleCloseSnack() {
        setSnackOpen(false);
    }

    function EventContent() {
        return (
            <>
                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                        {snackMessage}
                    </Alert>
                </Snackbar>
                <Paper elevation={10} sx={{
                    border: "1px solid black",
                    width: "80%",
                    marginLeft: "10%",
                    marginTop: "4vh",
                    padding: "25px 50px 50px 50px"
                }}>
                    <Typography variant={"h5"} sx={{textAlign: "center", mb: 3}}>{event.event_name}</Typography>
                    <Stack direction={"row"} spacing={2} sx={{justifyContent: "space-between", mb: 10}}>
                        <Button variant={"contained"} color={"primary"} onClick={activateInfo}>Event Info</Button>
                        <ButtonGroup variant={"contained"}>
                            <ButtonBase sx={{width: "30px", flexDirection: "column", color: "white"}} disabled>
                                <Box sx={{backgroundColor: "success.light", display: "block", width: "100%", height: "100%", borderTopLeftRadius: "4px", fontSize: "0.7rem"}}>
                                    <Typography variant="span" sx={{position: "absolute", top: "25%", left: "0", right: "0", transform: "translateY(-50%)"}}>{event.invites.amount_received}</Typography>
                                </Box>
                                <Box sx={{backgroundColor: "error.light", display: "block", width: "100%", height: "100%", borderBottomLeftRadius: "4px"}}>
                                    <Typography variant="span" sx={{position: "absolute", top: "75%", left: "0", right: "0", transform: "translateY(-50%)", fontSize: "0.7rem"}}>{event.invites.amount_not_received}</Typography>
                                </Box>
                            </ButtonBase>
                            <Button color={"primary"} onClick={activateSendInvites} endIcon={<SendIcon />} disabled={isFinishedEvent}>Send Invites</Button>
                            <Button color={"primary"} onClick={activateCustomMessage} endIcon={<SendIcon />}>Custom message</Button>
                        </ButtonGroup>
                        <ButtonGroup variant={"contained"}>
                            {isActiveEvent && <ButtonBase sx={{width: "30px", flexDirection: "column", color: "white"}} disabled>
                                <Box sx={{
                                    backgroundColor: "success.light",
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                    borderTopLeftRadius: "4px",
                                    fontSize: "0.7rem"
                                }}>
                                    <Typography variant="span" sx={{
                                        position: "absolute",
                                        top: "25%",
                                        left: "0",
                                        right: "0",
                                        transform: "translateY(-50%)"
                                    }}>{eventUsersScanned}</Typography>
                                </Box>
                                <Box sx={{
                                    backgroundColor: "error.light",
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                    borderBottomLeftRadius: "4px"
                                }}>
                                    <Typography variant="span" sx={{
                                        position: "absolute",
                                        top: "75%",
                                        left: "0",
                                        right: "0",
                                        transform: "translateY(-50%)",
                                        fontSize: "0.7rem"
                                    }}>{(event.users.length - eventUsersScanned)}</Typography>
                                </Box>
                            </ButtonBase>}
                            <Button variant={"contained"} color={"primary"} onClick={activateUsers} startIcon={<PersonIcon />}>Event Users</Button>
                        </ButtonGroup>
                    </Stack>
                    <Stack direction={"row"} spacing={2} sx={{justifyContent: "space-between"}}>
                        <ButtonGroup variant={"contained"}>
                            <Button color={"primary"} onClick={activateCreateTask}>Create Task</Button>
                            <Button color={"primary"} onClick={activateViewTasks}>View Tasks</Button>
                            <Button color={"primary"} onClick={activateVendorTasks}>Vendor Tasks</Button>
                        </ButtonGroup>
                        <ButtonGroup variant={"contained"}>
                            <Button color={"primary"} onClick={activateViewSchedule} startIcon={<EventNoteIcon />}>View Schedule</Button>
                        </ButtonGroup>
                    </Stack>
                </Paper>
                <EventInfoDialog eventId={event_id} dialogOpen={infoDialogOpen} setDialogOpen={setInfoDialogOpen} />
                <EventUsersDialog eventId={event_id} dialogOpen={usersDialogOpen} setDialogOpen={setUsersDialogOpen} refresh={getEventInfo} />
                <CreateTaskDialog eventId={event_id} dialogOpen={createTaskDialogOpen} setDialogOpen={setCreateTaskDialogOpen} />
                <ViewTasksDialog eventId={event_id} dialogOpen={viewTaskDialogOpen} setDialogOpen={setViewTaskDialogOpen} />
                <VendorTasksDialog eventId={event_id} dialogOpen={vendorTasksDialogOpen} setDialogOpen={setVendorTasksDialogOpen} />
                <ViewScheduleDialog eventId={event_id} dialogOpen={viewScheduleDialogOpen} setDialogOpen={setViewScheduleDialogOpen} />
                <ConfirmSendInvitationsDialog eventId={event_id} dialogOpen={confirmSendInvitationsDialogOpen} setDialogOpen={setConfirmSendInvitationsDialogOpen} setSnackOpen={setSnackOpen} setSnackMessage={setSnackMessage} triggerEventUpdate={triggerEventUpdate} />
                <CustomMessageDialog eventId={event_id} dialogOpen={customMessageDialogOpen} setDialogOpen={setCustomMessageDialogOpen} preSelected={customMessageDialogPreSelected} />
            </>
        )
    }

    return (
        <>
        {event ? (<EventContent />) : (<CenteredLoading />)}
        </>
    );
}