import {useContext, useEffect, useState} from "react";
import {getChatMembers} from "../lib/getAPI.js";
import {EventContext} from "../../../routers/userRouter.jsx";
import {Box, Paper} from "@mui/material";

export function SeeMembers({chatId}) {
    const eventId = useContext(EventContext);
    const [chatMembers, setChatMembers] = useState([]);

    useEffect(() => {
        if (chatId) {
            getMembers();
        }
    }, [chatId]);

    async function getMembers() {
        const res = await getChatMembers(eventId, chatId);
        if (res.chatMembers) {
            setChatMembers(res.chatMembers?.sort((a, b) => {
                return a.user_firstname.localeCompare(b.user_firstname);
            }));
        }
    }

    return (
        <Box sx={{overflow: "scroll", height: "100vh"}}>
            {chatMembers && chatMembers.map((member) => {
                return (
                    <Paper sx={{margin: 1, padding: "5px 10px", fontSize: "28px"}} elevation={2} key={member.user_id}>
                        {`${member.user_firstname} ${member.user_lastname}`}
                    </Paper>
                )
            })}
            <Box sx={{height: "70px"}}></Box>
        </Box>
    )
}