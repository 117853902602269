import {
    AppBar, Badge,
    Box,
    Drawer, FormControl,
    IconButton, InputLabel, List, ListItem,
    ListItemButton,
    ListItemText, MenuItem, NativeSelect, Select, styled,
    Toolbar, Typography
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Logo from "./../../../images/power-logo-svg.svg";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import ChatIcon from '@mui/icons-material/Chat';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AssignmentIcon from '@mui/icons-material/Assignment';
import POWGoLow from "./../../../images/POW-06-GoLow-HN-v01_500x500.png";
import {dateFormatWithLinesSwapped} from "../../../lib/dateFormat.js";
import GetAppIcon from '@mui/icons-material/GetApp';
import {TasksAmountContext} from "../../../routers/userRouter.jsx";

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

function NavButton({nav, navTarget, onClickCustom, Icon, badgeAmount, children}) {
    const isCurrentPage = window.location.pathname === navTarget;

    function handleClick() {
        nav(navTarget);
        onClickCustom();
    }

    return (
        <ListItem sx={{ borderBottomWidth: "1px", borderBottomStyle: "solid", borderColor: "secondary.dark", backgroundColor: (window.location.pathname === navTarget ? "primary.light" : "") }} disablePadding>
            <ListItemButton onClick={handleClick}>
                {Icon && <Badge badgeContent={badgeAmount || 0} color="powerCustom" sx={{marginRight: "12px"}}><Icon fontSize="large" sx={{color: (isCurrentPage ? "powerCustom.main" : "secondary.dark")}}/></Badge>}
                <ListItemText primary={children} primaryTypographyProps={{fontSize: "1.6rem"}} />
            </ListItemButton>
        </ListItem>
    );
}

export function UserHeaderComponent({events, currentEventId, setCurrentEventId, refresh}) {
    const [drawerActive, setDrawerActive] = useState(false);
    const tasksAmount = useContext(TasksAmountContext);
    const [installPrompt, setInstallPrompt] = useState(null);
    const nav = useNavigate();

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            /*setInstallPrompt(e);*/
        });
    });

    function toggleDrawer(x) {
        if (x === undefined) {
            setDrawerActive(!drawerActive);
        }
        setDrawerActive(x);
    }

    function handleChangeEvent(event) {
        setCurrentEventId(event.target.value);

        // Store selected event in session storage
        sessionStorage.setItem("selectedEventId", event.target.value);
    }

    return (
        <div>
            <AppBar position="fixed">
                <Toolbar sx={{height: "70px"}}>
                    <Box
                        component="img"
                        sx={{
                            height: "40px",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)"
                        }}
                        align="center"
                        alt="Your logo."
                        src={Logo}
                    />
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{padding: "20px", marginLeft: "-15px"}}
                        onClick={() => toggleDrawer(true)}
                    >
                        <MenuIcon fontSize="inherit" sx={{scale: "1.8", backgroundColor: "primary.dark", borderRadius: "5px", padding: "4px"}} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar sx={{height: "70px"}} />
            <Drawer
                open={drawerActive}
                onClose={() => toggleDrawer(false)}
                anchor="left"
                PaperProps={{
                    sx: {
                        backgroundColor: "primary.main"
                    }
                }}
                componentsProps={{ // blur instead of darkening
                    backdrop: {
                        sx: {
                            backdropFilter: "blur(2px)",
                            //backgroundColor: "transparent",
                            backgroundColor: "rgba(0, 0, 0, 0.1)",
                            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, backgroundColor 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                        }
                    }
                }}
            >
                <Box
                    role="presentation"
                    sx={{ width: 250, maxWidth: "70vw", color: "primary.contrastText" }}
                >
                    <List sx={{ padding: "0" }}>
                        {/*<NavButton nav={nav} navTarget={"/user"} onClickCustom={toggleDrawer} Icon={HomeIcon}>Home</NavButton>*/}
                        <NavButton nav={nav} navTarget={"/user/info"} onClickCustom={toggleDrawer} Icon={InfoIcon}>Info</NavButton>
                        <NavButton nav={nav} navTarget={"/user/schedule"} onClickCustom={toggleDrawer} Icon={EventNoteIcon}>Schedule</NavButton>
                        <NavButton nav={nav} navTarget={"/user/tasks"} onClickCustom={toggleDrawer} badgeAmount={tasksAmount?.amount} Icon={AssignmentIcon}>Tasks</NavButton>
                        <NavButton nav={nav} navTarget={"/user/group"} onClickCustom={toggleDrawer} Icon={GroupsIcon}>Group</NavButton>
                        <NavButton nav={nav} navTarget={"/user/chat"} onClickCustom={toggleDrawer} Icon={ChatIcon}>Chat</NavButton>
                        <NavButton nav={nav} navTarget={"/user/qrcode"} onClickCustom={toggleDrawer} Icon={QrCode2Icon}>QR code</NavButton>
                        {installPrompt && <NavButton nav={nav} navTarget={"#"} onClickCustom={() => installPrompt.prompt()}
                                    Icon={GetAppIcon}>Download</NavButton>}
                    </List>
                    <Box sx={{
                        width: "60%",
                        marginLeft: "20%",
                        marginTop: "40px",
                        boxSizing: "border-box"
                    }}>
                        <Box
                            component="img"
                            src={POWGoLow}
                            sx={{
                                width: "100%"
                            }}
                        />
                        <Typography
                            variant="h5"
                            sx={{
                                display: "inline-block",
                                fontWeight: "bold",
                                fontSize: "2.7rem",
                                lineHeight: "1",
                            }}
                        >Hel<Typography variant="span" sx={{color: "powerCustom.main"}}>low</Typography>
                            <Typography
                                variant="span"
                                sx={{fontSize: "2rem"}}
                            > - let's go!</Typography></Typography>
                    </Box>
                    <Box
                        sx={{
                            //backgroundColor: "green",
                            //visibility: "hidden"
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            padding: "5px"
                        }}
                    >
                        <FormControl fullWidth>
                            <NativeSelect
                                value={currentEventId}
                                onChange={handleChangeEvent}
                                sx={{
                                    '&:before': {
                                        border: "none"
                                    },
                                    '&:after': {
                                        border: "none"
                                    },
                                    '&:not(.Mui-disabled):hover::before': {
                                        border: 'none'
                                    },
                                    '& .MuiNativeSelect-icon': {
                                        color: "white"
                                    },
                                    color: "white",
                                    backgroundColor: "primary.dark",
                                    borderRadius: "5px",
                                    padding: "5px 5px 5px 10px",
                                }}
                            >
                                {events.sort((a, b) => b.event_id - a.event_id).map(event => <option style={{color: "black"}} key={event.event_id} value={event.event_id}>{event.event_name}</option>)}
                            </NativeSelect>
                        </FormControl>
                    </Box>
                </Box>
            </Drawer>
        </div>
    );
}