import {useNavigate} from "react-router-dom";
import {AppBar, Box, Toolbar} from "@mui/material";
import Logo from "../../../../images/power-logo-svg.svg";

export function LoginHeaderComponent() {
    const nav = useNavigate();

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar sx={{height: "70px"}}>
                    <Box
                        component="img"
                        sx={{
                            height: "40px",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                        align="center"
                        alt="Your logo."
                        src={Logo}
                    />
                </Toolbar>
            </AppBar>
        </div>
    );
}