import {Box, Grid, Tooltip} from "@mui/material";
import {colorFromRange} from "../../../../lib/colorFromRange.js";
import React from "react";

export function ScheduleDisplayGrid({selectedDate}) {
    const gridRows = [...new Set(selectedDate.map(item => item.schedule_start_time))].sort((a, b) => a.localeCompare(b));
    const groups = [...new Set(selectedDate.map(item => item.group_id))].sort((a, b) => a - b);
    const allPlans = [...new Set(selectedDate.map(item => item.schedule_item_name))];
    //const colors = ["#1679fa", "#fa1616", "#16fa53", "#fcf403"];
    //const colors = ["#1679fa", "#fa1616", "#16fa53", "#a8a400"]; "#ba5cc2", "#ce528f"
    const colors = ["#bf6776", "#cf4b3d", "#c47e40", "#bcae44", "#677b35", "#65b549", "#57b185", "#45aecf", "#8080c5", "#6e64d2"];

    return (
        <Grid container spacing={1} sx={{
            width: "100%",
            display: "grid",
            gridTemplateRows: `repeat(${gridRows.length + 1}, auto)`,
            gridTemplateColumns: `repeat(${Math.max(groups) + 1}, 1fr)`,
        }}>
            {groups.map(item => (
                <Grid key={item} item xs sx={{
                    gridColumnStart: item + 1,
                    gridRowStart: 1
                }}>
                    <Box sx={{
                        padding: "16px",
                        textAlign: "center",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "4px",
                    }}>
                        Group {item}
                    </Box>
                </Grid>
            ))}
            {gridRows.map(item => (
                <Grid key={item} item xs sx={{
                    gridColumnStart: 1,
                    gridRowStart: gridRows.indexOf(item) + 2
                }}>
                    <Tooltip title={item + " - " + selectedDate.filter(x => x.schedule_start_time === item)[0].schedule_end_time}>
                        <Box sx={{
                            padding: "16px",
                            textAlign: "center",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "4px"
                        }}>
                            {item}
                        </Box>
                    </Tooltip>
                </Grid>
            ))}
            {selectedDate.map(item => (
                <Grid key={item.schedule_id} item xs sx={{
                    gridColumnStart: item.group_id + 1,
                    gridRowStart: gridRows.indexOf(item.schedule_start_time) + 2
                }}>
                    <Box sx={{
                        padding: "16px",
                        textAlign: "center",
                        backgroundColor: colorFromRange(colors, allPlans.indexOf(item.schedule_item_name) / allPlans.length),
                        borderRadius: "4px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap"
                    }}>
                        {item.schedule_item_name}
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
}