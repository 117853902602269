import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {patchUserGroup} from "../../../lib/postAPI.js";

export function ChangeGroupDialog({eventId, dialogOpen, setDialogOpen, user, refresh}) {
    const [group, setGroup] = useState(null);

    useEffect(() => {
        if (dialogOpen) {
            setGroup(user.group_number);
        }
    }, [dialogOpen]);

    async function handleChangeUserGroup() {
        const res = await patchUserGroup(eventId, user.user_id, {group});

        if (res.error) {
            console.log(res.error);
            alert("Error when changing group: "+res.error);
        } else {
            handleCloseDialog();
            refresh();
        }
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    return (
        <>
            {user &&
                <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                    <DialogTitle>Change Group for {user.user_email}</DialogTitle>
                    <DialogContent>
                        <TextField type={"number"} value={group || ""} onChange={event => setGroup(event.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleChangeUserGroup}>
                            Save
                        </Button>
                        <Button onClick={handleCloseDialog}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>}
        </>
    );
}