import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Menu,
    MenuItem,
    Paper,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs
} from "@mui/material";
import {useEffect, useState} from "react";
import {getEventAllInfo, getTasksFromEvent} from "../../../lib/getAPI.js";
import {ageFromDOB} from "../../../../../lib/ageFromDOB.js";
import {dateFormat, dateFormatWithLinesSwapped} from "../../../../../lib/dateFormat.js";
import {postCheckin, postMakeUserHost} from "../../../lib/postAPI.js";
import {CustomMessageDialog} from "./customMessageDialog.jsx";
import {AddUsersDialog} from "./addUsersDialog.jsx";
import {CenteredLoading} from "../../../../../components/centeredLoading.jsx";
import {ChangeGroupDialog} from "./changeGroupDialog.jsx";
import {DisinviteUserDialog} from "./disinviteUserDialog.jsx";

const columns = [
    {id: "user_email", align: "left", minWidth: 170, label: "Email"},
    {id: "user_name", align: "left", minWidth: 170, label: "Name"},
    {id: "user_office", align: "left", minWidth: 130, label: "Office"},
    {id: "user_department", align: "left", minWidth: 130, label: "Department"},
    {id: "group_number", align: "left", minWidth: 50, label: "Group"},
    {id: "user_phone", align: "left", minWidth: 140, label: "Phone number"}
];

export function EventUsersDialog({eventId, dialogOpen, setDialogOpen, refresh}) {
    const [tabValue, setTabValue] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [event, setEvent] = useState(null);
    const [tasks, setTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [addUsersDialogOpen, setAddUsersDialogOpen] = useState(false);
    const [customMessageDialogOpen, setCustomMessageDialogOpen] = useState(false);
    const [changeGroupDialogOpen, setChangeGroupDialogOpen] = useState(false);
    const [disinviteUserDialogOpen, setDisinviteUserDialogOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuUser, setMenuUser] = useState(null);
    const menuOpen = Boolean(anchorEl);

    useEffect(() => {
        if (dialogOpen) {
            getUsers();
            getTasks();
        }
    }, [dialogOpen]);

    function reload() {
        getUsers();
        getTasks();
    }

    async function getUsers() {
        const event = (await getEventAllInfo(eventId)).event;

        if (event) {
            if (dateFormat(new Date()) >= dateFormat(new Date(event.event_start_date)) && dateFormat(new Date()) <= dateFormat(new Date(event.event_end_date))) {
                setSelectedDate(dateFormatWithLinesSwapped(new Date()));
            } else {
                setSelectedDate(dateFormatWithLinesSwapped(new Date(event.event_start_date)));
            }

            const formattedEvent = {
                ...event,
                event_start_date: dateFormatWithLinesSwapped(new Date(event.event_start_date)),
                event_end_date: dateFormatWithLinesSwapped(new Date(event.event_end_date)),
                days: event.days.map((day) => {
                    return {
                        ...day,
                        date: dateFormatWithLinesSwapped(new Date(day.date))
                    }
                }),
                users: event.users.map((u) => {
                    return {
                        ...u,
                        user_name: u.user_firstname + " " + u.user_lastname,
                        age: ageFromDOB(u.user_dob),
                        days: u.days.map((day) => {
                            return {
                                ...day,
                                date: dateFormatWithLinesSwapped(new Date(day.date))
                            }
                        })
                    }
                }).sort((a, b) => {
                    return a.user_name.localeCompare(b.user_name);
                })
            }

            setEvent(formattedEvent);
        }
    }

    async function getTasks() {
        const tasks = await getTasksFromEvent(eventId);
        setTasks(tasks);
        setSelectedTask(tasks.tasks[0]?.task_id);
    }

    function handleChangeTab(event, newValue) {
        setTabValue(newValue);
    }

    function handleOpenMenu(event, id) {
        setMenuUser(id);
        setAnchorEl(event.currentTarget);
    }

    function handleCloseMenu() {
        setAnchorEl(null);
    }

    function handleCloseDialog() {
        setDialogOpen(false);
        refresh();
    }

    function handleAddUsers() {
        setAddUsersDialogOpen(true);
    }

    async function makeUserHost(val) {
        const res = await postMakeUserHost(eventId, {userId: menuUser.user_id, changeTo: val});
        if (res?.success) {
            reload();
        } else if (res.error) {
            alert(res.error);
        } else {
            console.log("Unknown error", res);
        }
    }

    function UserMenu() {
        async function ManualCheckin() {
            // NEEDS SELECTED DATE
            const res = await postCheckin(eventId, menuUser.user_id);

            if (res.message === "User verified") {
                reload();
            }
        }

        function userHasPhone() {
            const u = event.users.find(u => {
                return u.user_id === menuUser.user_id;
            });
            if (u) {
                return u.user_phone !== "";
            } else {
                return false;
            }
        }

        return (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={() => {
                    handleCloseMenu();
                    setCustomMessageDialogOpen(true);
                }} disabled={!userHasPhone()}>Send message</MenuItem>
                {tabValue === 1 &&
                    <MenuItem onClick={() => {
                        ManualCheckin();
                        handleCloseMenu();
                    }} disabled={selectedDate !== dateFormatWithLinesSwapped(new Date)}>Manual check-in</MenuItem>}
                <MenuItem onClick={() => {
                    handleCloseMenu();
                    setChangeGroupDialogOpen(true);
                }}>Change group</MenuItem>
                <MenuItem onClick={() => {
                    handleCloseMenu();
                    makeUserHost(menuUser.event_role !== "HOST");
                }}>{(menuUser.event_role === "HOST" ? "Remove HOST" : "Make HOST")}</MenuItem>
                <MenuItem onClick={() => {
                    handleCloseMenu();
                    setDisinviteUserDialogOpen(true);
                }}>Dis-invite user</MenuItem>
                <MenuItem onClick={handleCloseMenu}>Close</MenuItem>
            </Menu>
        );
    }

    return (
        <>
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth={"xl"} fullWidth={true}>
                <DialogTitle>Event Users</DialogTitle>
                <DialogContent sx={{overflow: "none"}}>
                    <Tabs value={tabValue} onChange={handleChangeTab} centered>
                        <Tab label="Users"/>
                        <Tab label="QR Scans"/>
                        <Tab label="Tasks"/>
                    </Tabs>
                    <Button variant={"contained"} sx={{mb: 1, mt: 1}} size={"large"} onClick={reload}>Refresh</Button>
                    {tabValue === 1 && (event &&
                        <Select
                            size={"small"}
                            value={selectedDate}
                            sx={{mb: 1, mt: 1, float: "right"}}
                            onChange={(e) => setSelectedDate(e.target.value)}
                        >
                            {event && event.days.map((d) => {
                                return (
                                    <MenuItem key={d.date} value={d.date}>Day {d.day}</MenuItem>
                                )
                            })}
                        </Select>
                    )}
                    {tabValue === 2 && (event &&
                        <Select
                            size={"small"}
                            value={selectedTask}
                            sx={{mb: 1, mt: 1, float: "right"}}
                            onChange={(e) => setSelectedTask(e.target.value)}
                        >
                            {tasks && tasks?.tasks.map((t) => {
                                return (
                                    <MenuItem key={t.task_id} value={t.task_id}>{t.task_label}</MenuItem>
                                )
                            })}
                        </Select>
                    )}
                    <Paper>
                        <TableContainer sx={{height: 440}}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{minWidth: column.minWidth, fontWeight: "bold"}}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        {tabValue === 1 &&
                                            <TableCell align={"left"} style={{minWidth: 170, fontWeight: "bold"}}>Check-in
                                                time</TableCell>}
                                    </TableRow>
                                </TableHead>
                                {event && <TableBody>
                                    {event.users.map((row) => {
                                        return (
                                            <TableRow role="checkbox" tabIndex={-1} key={row.user_id}
                                                      sx={{
                                                          cursor: "pointer",
                                                          backgroundColor: (row.event_role === "HOST" ? "rgba(255,255,0,0.3)" : (tabValue === 1 ?
                                                              (row.days.find(d => d.date === selectedDate).scanned ? "rgba(0,255,0,0.3)" : "rgba(255,0,0,0.3)") : (
                                                                  tabValue === 2 ? (tasks.users.find(u => u.user_id === row.user_id && u.task_id === selectedTask) ? "rgba(0,255,0,0.3)" : "rgba(255,0,0,0.3)") : ""
                                                              )))
                                                      }}
                                                      onClick={(e) => {
                                                          handleOpenMenu(e, row)
                                                      }}
                                            >
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                                {tabValue === 1 && <TableCell
                                                    align={"left"}>{new Date(row.days.find(d => d.date === selectedDate).time)?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }) === "Invalid Date" ? "" : new Date(row.days.find(d => d.date === selectedDate).time)?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).substring(0, 5)}</TableCell>}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>}
                            </Table>
                        </TableContainer>
                        {!event && <CenteredLoading />}
                    </Paper>
                </DialogContent>
                <DialogActions sx={{justifyContent: "space-between"}}>
                    <Button onClick={handleAddUsers} variant={"contained"}>Add users</Button>
                    <Button onClick={handleCloseDialog}>Exit</Button>
                </DialogActions>
                {(event && menuUser) && <UserMenu/>}
            </Dialog>
            <CustomMessageDialog eventId={eventId} dialogOpen={customMessageDialogOpen}
                                 setDialogOpen={setCustomMessageDialogOpen}
                                 preSelected={menuUser}/>
            <AddUsersDialog eventId={eventId} dialogOpen={addUsersDialogOpen} setDialogOpen={setAddUsersDialogOpen} defaultExcelChecked={event && event.users.length === 0} refresh={getUsers} />
            <ChangeGroupDialog eventId={eventId} dialogOpen={changeGroupDialogOpen} setDialogOpen={setChangeGroupDialogOpen} user={menuUser} refresh={getUsers} />
            <DisinviteUserDialog eventId={eventId} dialogOpen={disinviteUserDialogOpen} setDialogOpen={setDisinviteUserDialogOpen} user={menuUser} refresh={getUsers} />
        </>
    );
}