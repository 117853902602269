import {AppBar, Box, IconButton, Menu, MenuItem, Toolbar} from "@mui/material";
import Logo from "../../../../images/power-logo-svg.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useRef, useState} from "react";

function OptionsMenuComponent({anchorEl, setAnchorEl, selectOption}) {
    const open = Boolean(anchorEl);

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Menu
            anchorEl={anchorEl}
            sx={{zIndex: "2000"}}
            open={open}
            onClose={handleClose}
        >
            <MenuItem onClick={()=>{
                selectOption(null);
                handleClose();
            }}>Chat</MenuItem>
            <MenuItem onClick={()=>{
                selectOption('seeMembers');
                handleClose();
            }}>See members</MenuItem>
        </Menu>
    );
}

export function ChatHeaderComponent({chatId, closeOverlay, selectOption}) {
    const menuRef = useRef(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);

    function optionsMenu() {
        setMenuAnchorEl(menuRef.current);
    }

    return (
        <>
            <AppBar className={"non-draggable"} position="fixed" sx={{overscrollBehavior: "none"}}>
                <Toolbar sx={{height: "70px"}}>
                    <Box
                        component="img"
                        sx={{
                            height: "40px",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)"
                        }}
                        align="center"
                        alt="Your logo."
                        src={Logo}
                    />
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{padding: "20px", marginLeft: "-15px"}}
                        onClick={closeOverlay}
                    >
                        <ArrowBackIcon fontSize="inherit" sx={{scale: "1.8", backgroundColor: "primary.dark", borderRadius: "5px", padding: "4px"}} />
                    </IconButton>
                    <IconButton
                        ref={menuRef}
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{padding: "20px", position: "absolute", right: "0"}}
                        onClick={optionsMenu}
                    >
                        <MoreVertIcon fontSize="inherit" sx={{scale: "1.8", backgroundColor: "primary.dark", borderRadius: "5px", padding: "4px"}} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar sx={{height: "70px"}} />
            <OptionsMenuComponent anchorEl={menuAnchorEl} setAnchorEl={setMenuAnchorEl} selectOption={selectOption} />
        </>
    );
}