import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {dateFormatWithLines} from "../../../../../lib/dateFormat.js";
import {useNavigate} from "react-router-dom";
import {postCreateEvent} from "../../../lib/postAPI.js";

export function CreateEventDialog({dialogOpen, setDialogOpen}) {
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
    const [eventName, setEventName] = useState("");
    const [startDateValue, setStartDateValue] = useState(dayjs(new Date()));
    const [endDateValue, setEndDateValue] = useState(dayjs(new Date()));
    const nav = useNavigate();

    useEffect(() => {
        if (eventName.length > 0) {
            setCreateButtonDisabled(false);
        } else {
            setCreateButtonDisabled(true);
        }
    }, [eventName]);

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    async function createEvent() {
        setButtonsDisabled(true);

        const startDate = dateFormatWithLines(startDateValue.$d);
        const endDate = dateFormatWithLines(endDateValue.$d);

        const res = await postCreateEvent({eventName, startDate, endDate});
        if (res.eventId) {
            nav("/admin/event/" + res.eventId);
        } else if (res.error) {
            alert(res.error);
        } else {
            alert("Unknown error");
        }
    }

    return (
        <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            maxWidth={"md"}
            fullWidth={true}
        >
            <DialogTitle>Create Event</DialogTitle>
            <DialogContent >
                <Box sx={{mt: 1, mb: 1, display: "flex", justifyContent: "space-between"}}>
                    <TextField label="Event name" variant="outlined" onChange={(newValue) => setEventName(newValue.target.value)} />
                    <DatePicker
                        label="Start date"
                        value={startDateValue}
                        onChange={(newValue) => setStartDateValue(newValue)}
                    />
                    <DatePicker
                        label="End date"
                        value={endDateValue}
                        onChange={(newValue) => setEndDateValue(newValue)}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={createEvent} color="primary" disabled={buttonsDisabled || createButtonDisabled}>
                    Create
                </Button>
                <Button onClick={handleCloseDialog} color="primary" disabled={buttonsDisabled}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}