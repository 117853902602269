import {Box, Card, CardContent, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {EventContext} from "../../../routers/userRouter.jsx";
import {getEventInfo, getGroup} from "../lib/getAPI.js";

export function GroupPage() {
    const eventId = useContext(EventContext);
    const [group, setGroup] = useState([]);
    const [groupNr, setGroupNr] = useState(null);

    useEffect(() => {
        if (eventId) {
            getGroupMembers();
        }
    }, [eventId]);

    async function getGroupMembers() {
        const resEvent = (await getEventInfo(eventId)).event;
        const groupRes = (await getGroup(eventId)).group;
        const {group_number} = groupRes[0];
        setGroupNr(group_number);
        setGroup(groupRes);
    }

    return (
        <div>
            <Box
                sx={{
                    m: 1
                }}
            >
                <Typography sx={{width: "100%", textAlign: "center"}} fontSize={"1.5em"}>Your group is: {groupNr}</Typography>
                {group.length > 0 && group.map(({user_email, user_firstname, user_lastname, user_department, user_office}) => (
                    <Card key={user_email} elevation={2} sx={{
                        border: "1px #ccc solid",
                        mt: 1,
                        mb: 1
                    }}>
                        <CardContent sx={{paddingBottom: "16px !important"}}>
                            <Typography fontSize={"1.5rem"}>{user_firstname + " " + user_lastname}</Typography>
                            <Typography fontSize={"0.8rem"} color="text.secondary">{user_office}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </div>
    );
}