import {useNavigate, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {getTaskWithItems} from "../lib/getAPI.js";
import {
    Box, Button,
    FormControl,
    InputLabel,
    MenuItem,
    NativeSelect,
    Paper,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {postCompleteTask} from "../lib/postAPI.js";
import {taskItemTypes} from "../../../lib/taskTypes.js";
import {TasksAmountContext} from "../../../routers/userRouter.jsx";

export function TaskPage() {
    const {task_id} = useParams();
    const [task, setTask] = useState(null);
    const tasksAmount = useContext(TasksAmountContext);
    const nav = useNavigate();

    useEffect(() => {
        getTask();
    }, []);

    async function getTask() {
        const res = (await getTaskWithItems(task_id)).task;
        if (res) {
            setTask(res);
        }
    }

    async function handleCompleteTask() {
        for (let i = 0; i < task.task_items.length; i++) {
            const item = task.task_items[i];
            if (!item.answer || item.answer.trim() === "") {
                alert("Please fill in all fields");
                return;
            }
        }

        const res = await postCompleteTask(task_id, {task_items: task.task_items});
        if (res) {
            tasksAmount.reload();
            nav("/user/tasks");
        }
    }

    function TaskItem({taskItem}) {
        function setItemVal(val) {
            if (val) {
                taskItem.answer = val;
            } else {
                delete taskItem.answer;
            }
        }

        function TaskItemInput({setItemVal}) {
            function handleChange(event) {
                if (event.target.value) {
                    setItemVal(event.target.value);
                } else {
                    setItemVal(null);
                }
            }
            return (
                <TextField variant="outlined" label={"Answer"} size={"small"} fullWidth onChange={handleChange} />
            );
        }
        function TaskItemSelect({options, setItemVal}) {
            function handleChange(event) {
                if (event.target.value) {
                    setItemVal(event.target.value);
                } else {
                    setItemVal(null);
                }
            }

            return (
                <FormControl fullWidth>
                    <InputLabel>Select</InputLabel>
                    <Select
                        defaultValue={""}
                        label="Select"
                        onChange={handleChange}
                    >
                        {options.map(o => (
                            <MenuItem key={o.task_item_option_id} value={o.task_item_option_value}>{o.task_item_option_label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        }

        return (
            <Paper sx={{p: 2}} variant={"outlined"}>
                <Typography sx={{fontSize: "22px", mb: 1}}>{taskItem.task_item_label}</Typography>
                {taskItem.task_item_answer_type === taskItemTypes.INPUT && (<TaskItemInput setItemVal={setItemVal} />)}
                {taskItem.task_item_answer_type === taskItemTypes.SELECT && (<TaskItemSelect setItemVal={setItemVal} options={taskItem.options} />)}
            </Paper>
        );
    }

    return (
        <Box sx={{p: 1}}>
            {task && <Typography variant={"h4"} sx={{textAlign: "center", mb: 1}}>{task.task_label}</Typography>}
            <Stack spacing={4}>
                {task && task.task_items.map(t => <TaskItem key={t.task_item_id} taskItem={t} />)}
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} m={2}>
                {task && <Button variant={"contained"} size={"large"} onClick={handleCompleteTask}>Complete</Button>}
            </Stack>
        </Box>
    );
}