import {
    Box,
    Button, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem, Select, Tooltip, Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getEventScheduleItems} from "../../../lib/getAPI.js";
import {postCreateVendorKey} from "../../../lib/postAPI.js";
import {SMSDialog} from "./smsDialog.jsx";

export function CreateVendorDialog({eventId, dialogOpen, setDialogOpen}) {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedLink, setSelectedLink] = useState(null);
    const [SMSDialogOpen, setSMSDialogOpen] = useState(false);

    useEffect(() => {
        if (dialogOpen) {
            getScheduleItems();
        }
    }, [dialogOpen]);

    async function getScheduleItems() {
        const res = await getEventScheduleItems(eventId);
        if (res) {
            setItems(res.scheduleItems.sort((a, b) => a.schedule_item_name.localeCompare(b.schedule_item_name)));
            setLoading(false);
        } else {
            setItems(null);
            setLoading(false);
        }
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    async function createVendorKey(item) {
        const res = await postCreateVendorKey(eventId, {scheduleItemId: item.schedule_item_id});
        if (res) {
            if (res.success) {
                getScheduleItems();
            } else if (res.error) {
                alert(res.error);
            }
        }
    }

    function handleOpenSMSDialog(link) {
        setSelectedLink(link);
        setSMSDialogOpen(true);
    }

    return (
        <>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                maxWidth={"md"}
                fullWidth={true}
            >
                <DialogTitle>Create vendor</DialogTitle>
                <DialogContent>
                    {loading ? <CircularProgress /> : <Box>
                        {items && items.map(item =>
                            <Box key={item.schedule_item_id} sx={{mb: 1, "&:hover": {backgroundColor: "#f3f3f3"}}}>
                                <Typography variant={"span"} sx={{display: "inline-block", width: "200px"}}>{item.schedule_item_name}</Typography>
                                <Button variant={"contained"} sx={{ml: 1, mr: 1}} disabled={item.schedule_item_vendor_link !== null} onClick={() => createVendorKey(item)}>Create key</Button>
                                <Tooltip title={item.schedule_item_vendor_link !== null ? window.location.origin + "/vendor/login?key=" + item.schedule_item_vendor_link : ""}>
                                    <Button sx={{display: "inline-block", width: "400px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", textTransform: "lowercase !important"}} onClick={()=>handleOpenSMSDialog(item.schedule_item_vendor_link !== null ? window.location.origin + "/vendor/login?key=" + item.schedule_item_vendor_link : "")}>{item.schedule_item_vendor_link !== null ? window.location.origin + "/vendor/login?key=" + item.schedule_item_vendor_link : ""}</Button>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <SMSDialog dialogOpen={SMSDialogOpen} setDialogOpen={setSMSDialogOpen} messageContent={selectedLink} />
        </>
    );
}