import powerBGLogo from "../images/power-logo-svg.svg";
import {QrReader} from 'react-qr-reader';
import React, {useEffect, useState} from "react";
import {fetchJSON} from "../lib/fetchJSON";
import {dateFormat} from "../lib/dateFormat";
import {postJSON} from "../lib/postJSON";
import {FormControl, NativeSelect} from "@mui/material";

function ScannerHeaderComponent() {
    return (
        <header className={"user-header power-sap-header-bg"}>
            <nav>
                <div className={"navigation-button"}>
                </div>
                <div className={"header-logo-container"} style={{backgroundColor: "#2A2F32"}}>
                    <img className={"header-logo"} src={powerBGLogo} alt=""/>
                </div>
                <div className={"navigation-button"}>
                </div>
            </nav>
        </header>
    );
}

export function ScannerQRPage() {
    const [scanResult, setScanResult] = useState(null);
    const [lastScanResult, setLastScanResult] = useState(null);
    const [bgColor, setBgColor] = useState("transparent");
    const [events, setEvents] = useState([]);
    const [eventId, setEventId] = useState(null);
    const [scanStatus, setScanStatus] = useState(null);
    const [username, setUsername] = useState(null);
    const [age, setAge] = useState(null);

    useEffect(() => {
        getEvents();
    }, []);
    useEffect(() => {
        console.log(scanResult);
    }, [scanResult]);

    async function getEvents() {
        const res = await fetchJSON("/api/scanner/events");
        const eventsFormatted = res.events.map((event) => {
            return {
                ...event,
                event_start_date: new Date(event.event_start_date),
                event_end_date: new Date(event.event_end_date),
            }
        }).filter((event) => {
            const now = dateFormat(new Date());
            return dateFormat(event.event_start_date) <= now && now <= dateFormat(event.event_end_date);
        }).sort((a, b) => b.event_id - a.event_id);
        console.log(eventsFormatted);
        setEvents(eventsFormatted);
        if (eventsFormatted.length > 0) {
            setEventId(eventsFormatted[0].event_id);
        }

        /*if (res.events.length > 0) {
            const lastEvent = res.events.sort((a, b) => {
                return b.event_id - a.event_id;
            })[0];
            console.log("Setting eventId to:", lastEvent.event_id);
            setEventId(lastEvent.event_id);
        }*/
    }

    function resetScanner() {
        setTimeout(() => {
            setScanResult(null);
            setUsername(null);
            setAge(null);
            setScanStatus(null);
            setBgColor("transparent");
        }, 4000)
    }

    async function processScanResult(result) {
        if (result !== null) {
            let event_id;
            setEventId((prevEventId) => {
                console.log(prevEventId);
                event_id = prevEventId;
                return prevEventId;
            });
            if (event_id) {
                console.log("THIS IS EVENT_ID:", event_id);
                const res = await postJSON(`/api/scanner/verify/${event_id}`, {qrCode: result});
                if (res.error === "User already scanned") {
                    setScanStatus("User already scanned");
                    setBgColor("red");
                } else if (res.error === "User not invited to event") {
                    setScanStatus("Error: user is not invited to event");
                    setBgColor("red");
                } else if (res.error === "Could not scan user") {
                    setScanStatus("Internal error: could not scan");
                    setBgColor("red");
                } else if (res.message === "User verified") {
                    setScanStatus("User scanned");
                    setBgColor("lightgreen");
                }
                if (res.name) {
                    setUsername(res.name);
                }
                if (res.dob) {
                    const today = new Date();
                    const age = res.dob.split(/[./]/);
                    const year = parseInt(age[2]);
                    const month = parseInt(age[1]);
                    const day = parseInt(age[0]);

                    let userAge = null;
                    if (month <= (today.getMonth() + 1) && day <= today.getDate()) {
                        userAge = today.getFullYear() - year;
                    } else {
                        userAge = today.getFullYear() - year - 1;
                    }
                    if (userAge < 18) {
                        setBgColor("yellow");
                    }
                    setAge(userAge);
                }

                resetScanner();
            } else {
                console.log("Event id is undefined");
            }
        }
        /*let _lastScanResult;
        setLastScanResult((prev) => {
            _lastScanResult = prev;
            return prev;
        });
        console.log(result, _lastScanResult);
        if (result !== _lastScanResult) {
            console.log("lastScanResult");
            setScanResult(result);
            setLastScanResult(result);
            setBgColor("lightgreen");
            resetScanner();
        } else {
            console.log("Prevented scan!");
        }*/
    }

    function handleEventChange(e) {
        console.log(e.target.value);
        setEventId(e.target.value);
    }

    return (
        <div>
            <ScannerHeaderComponent />
            <FormControl fullWidth>
                <NativeSelect
                    sx={{
                        padding: "0.5rem",
                        fontSize: "1.2rem"
                    }}
                    disableUnderline
                    onChange={handleEventChange}
                >
                    {events.length > 0 ? events.map(({event_id, event_name}) => {
                        return <option key={event_id} value={event_id}>{event_name}</option>
                    }) : <option>No available events</option>}
                </NativeSelect>
            </FormControl>
            <QrReader className={"qr-reader"}
                      style={{width: "100%",
                          height: "100%",
                          display: "block",
                          boxSizing: "border-box"}}
                      videoStyle={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover"
                      }}
                constraints={{
                    facingMode: 'environment'
                }}
                onResult={(result, error) => {
                    if (!!result) {
                        const d = result?.text;
                        processScanResult(d);
                    }

                    if (!!error) {
                        console.info(error);
                    }
                }}
            />
            <div className={"scanner-result-bg"} style={{
                backgroundColor: bgColor,
                position: "absolute",
                bottom: "0",
                left: "0",
                right: "0",
                top: "0",
                zIndex: "-1"
            }} />
            <div className={"scanner-result"} style={{
                width: "100%",
                fontSize: "34px",
                textAlign: "center"
            }}>
                {username && <p>{username}<br/>({age})</p>}{scanStatus}
            </div>
        </div>
    );
}