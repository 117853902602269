import {FrontPage} from "../pages/user/frontPage/frontPage.jsx";
import {Route, Routes, useNavigate} from "react-router-dom";
import {InfoPage} from "../pages/user/infoPage/infoPage.jsx";
import {SchedulePage} from "../pages/user/schedulePage/schedulePage.jsx";
import {GroupPage} from "../pages/user/groupPage/groupPage.jsx";
import {QrCodePage} from "../pages/user/qrCodePage/qrCodePage.jsx";
import {LoginPage} from "../pages/user/loginPage/loginPage.jsx";
import {getEvents, getTasksFromEvent, getUser} from "../pages/user/lib/getAPI.js";
import {useEffect, useState} from "react";
import {UserHeaderComponent} from "../pages/user/components/userHeaderComponent";
import React from "react";
import {dateFormat} from "../lib/dateFormat.js";
import {TasksPage} from "../pages/user/tasksPage/tasksPage.jsx";
import {TaskPage} from "../pages/user/tasksPage/taskPage.jsx";
import {ChatPage} from "../pages/user/chatPage/chatPage.jsx";

export const EventContext = React.createContext(null);
export const TasksAmountContext = React.createContext(null);

export function UserRouter() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentEventId, setCurrentEventId] = useState(null);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(null);
    const [tasksAmount, setTasksAmount] = useState(0);
    const nav = useNavigate();

    useEffect(() => {
        loginCheck();
    }, []);

    useEffect(() => {
        if (currentEventId) {
            reloadTasksAmount();
        }
    }, [currentEventId]);

    function loginCallback() {
        loginCheck();
    }

    async function loginCheck() {
        setLoading(true);
        const res = await getUser();
        if (res.error) {
            setIsLoggedIn(false);
            nav("/user/login");
        } else if (res.user) {
            setIsLoggedIn(true);

            const events = (await getEvents()).events;

            const closestEvent = getClosestEvent(events);
            if (closestEvent) {
                const sessionEvent = sessionStorage.getItem("selectedEventId");
                const eventIds = events.map(event => event.event_id);
                const event_id = parseInt(sessionEvent) ? (eventIds.includes(parseInt(sessionEvent)) ? parseInt(sessionEvent) : closestEvent.event_id) : closestEvent.event_id
                setCurrentEventId(event_id);
            } else {
                setCurrentEventId(null);
            }
            setEvents(events);
        }
        setLoading(false);
    }

    async function reloadTasksAmount() {
        const res = (await getTasksFromEvent(currentEventId)) || {tasks: []};
        const amount = res.tasks.filter(t => !t.completed).length;
        setTasksAmount({amount, reload: reloadTasksAmount});
    }

    function getClosestEvent(events) {
        const now = dateFormat(new Date());
        let closestEvent = null;
        for (let event of events) {
            const start = dateFormat(new Date(event.event_start_date));
            const end = dateFormat(new Date(event.event_end_date));

            if (start <= now && now <= end) { // Check for currently active event
                if (!closestEvent ||
                    start < dateFormat(new Date(closestEvent.event_start_date)) ||
                    (start === dateFormat(new Date(closestEvent.event_start_date)) && event.event_id > closestEvent.event_id)) {
                    closestEvent = event;
                }
            }
        }
        if (!closestEvent) {
            for (let event of events) { // Check for closest upcoming event
                const start = dateFormat(new Date(event.event_start_date));

                if (now < start) {
                    if (!closestEvent ||
                        start < dateFormat(new Date(closestEvent.event_start_date)) ||
                        (start === dateFormat(new Date(closestEvent.event_start_date)) && event.event_id > closestEvent.event_id)) {
                        closestEvent = event;
                    }
                }
            }
        }
        if (!closestEvent) {
            for (let event of events) { // Check for closest past event
                const end = dateFormat(new Date(event.event_end_date));

                if (end < now) {
                    if (!closestEvent ||
                        end > dateFormat(new Date(closestEvent.event_end_date)) ||
                        (end === dateFormat(new Date(closestEvent.event_end_date)) && event.event_id > closestEvent.event_id)) {
                        closestEvent = event;
                    }
                }
            }
        }

        return closestEvent;
    }

    return (
        <>
            <EventContext.Provider value={currentEventId}>
            <TasksAmountContext.Provider value={tasksAmount}>
            {isLoggedIn && <UserHeaderComponent events={events} currentEventId={currentEventId} setCurrentEventId={setCurrentEventId} />}
            <Routes>
                <Route path={"/login"} element={<LoginPage loginCallback={loginCallback} />} />
                {isLoggedIn && (
                    (!loading && currentEventId === null) ? <Route path={"/*"} element={<>No selected event</>} /> :
                    <>
                        <Route path={"/"} element={<InfoPage />} />
                        <Route path={"/info"} element={<InfoPage />} />
                        <Route path={"/schedule"} element={<SchedulePage />} />
                        <Route path={"/group"} element={<GroupPage />} />
                        <Route path={"/tasks"} element={<TasksPage />} />
                        <Route path={"/chat"} element={<ChatPage />} />
                        <Route path={"/task/:task_id"} element={<TaskPage />} />
                        <Route path={"/qrcode"} element={<QrCodePage />} />
                    </>
                )}
            </Routes>
            </TasksAmountContext.Provider>
            </EventContext.Provider>
        </>
    );
}