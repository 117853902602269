import {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";

export function TaskItemDisplaySelect({taskItem}) {
    const [options, setOptions] = useState(null);
    const [label, setLabel] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const optionsWithAnswers = taskItem.options.map(o => {
            const amountOfAnswers = taskItem.answers.filter(a => a.task_item_answer_value === o.task_item_option_value).length;
            const aLength = taskItem.answers.length;
            const percentAnswers = Math.round(amountOfAnswers / (aLength > 0 ? aLength : 1) * 100);
            return {
                ...o,
                amountOfAnswers,
                percentAnswers: percentAnswers
            };
        });
        setOptions(optionsWithAnswers);
        setLabel(taskItem.task_item_label);
        setLoading(false);
    }, []);

    function TaskItemDisplaySelectItem({taskItemOption}) {
        return (
            <Box sx={{
                flex: "1 1 0",
                maxWidth: "17%",
                textAlign: "center",
                margin: 1,
                flexBasis: "20%",
                //backgroundColor: "grey.200",
                borderRadius: "4px",
                padding: "5px",
                backgroundColor: () => {
                    const {r, g, b} = createColor(taskItemOption.percentAnswers);
                    return `rgb(${r}, ${g}, ${b})`;
                }
            }}>
                <Typography sx={{fontSize: "1.5rem", fontWeight: "bold", mb: 1}}>{taskItemOption.percentAnswers}%</Typography>
                <Typography>{taskItemOption.task_item_option_label}</Typography>
            </Box>
        );
    }

    return (
        <Box padding={1}>
            {!loading && <>
                <Typography variant={"h5"} mb={1}>{label}</Typography>
                <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    margin: "0 auto",
                    padding: "20px"
                }}>
                    {options && options.map(o => (
                        <TaskItemDisplaySelectItem key={o.task_item_option_id} taskItemOption={o}/>
                    ))}
                </Box>
            </>}
        </Box>
    );
}

function createColor(percent) {
    const r = 200 - percent;
    const g = 200;
    const b = 200 - percent;
    return {r, g, b}
}