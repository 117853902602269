import {Box, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import QRCode from "react-qr-code";
import {getUser} from "../lib/getAPI.js";
import {CenteredLoading} from "../../../components/centeredLoading.jsx";

export function QrCodePage() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        getUserInfo();
    }, []);

    async function getUserInfo() {
        const resUser = (await getUser()).user;
        setUser(resUser);
    }

    return (
        <div>
            {user && <Typography variant="h4"
                         sx={{textAlign: "center", mt: 2}}>{user.user_firstname} {user.user_lastname}</Typography>}
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "90%",
                }}
            >
                {user ? (<div className={"qr-code-container"}>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%", maxHeight: "70vh" }}
                        fgColor={"#ec6608"}
                        value={user.user_qr_code}
                        viewBox={`0 0 256 256`}
                    />
                </div>) : <CenteredLoading />}
            </Box>
        </div>
    );
}