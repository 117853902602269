import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {getEvent} from "../../../lib/getAPI.js";
import {patchUpdateEventInfo} from "../../../lib/postAPI.js";

export function EventInfoDialog({eventId, dialogOpen, setDialogOpen}) {
    const [info, setInfo] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        if (dialogOpen) {
            getInfo();
        }
    }, [dialogOpen]);

    async function getInfo() {
        const res = (await getEvent(eventId)).event;
        if (res) {
            setInfo(res.event_info);
        }
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    async function saveInfo() {
        const res = await patchUpdateEventInfo(eventId, {event_info: info});
        if (res.message === "success") {
            handleCloseDialog();
        } else {
            setError(true);
        }
    }

    return (
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>Event Info</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    This text is shown to the users on the info page of the event.
                </DialogContentText>
                <TextField
                    margin="dense"
                    label="Event Info"
                    type="text"
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={15}
                    defaultValue={info}
                    onChange={(e) => setInfo(e.target.value)}
                />
                {error && <DialogContentText color={"error"}>Error updating event info</DialogContentText>}
            </DialogContent>
            <DialogActions>
                <Button onClick={saveInfo}>Save</Button>
                <Button onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}