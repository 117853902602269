import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getTasksFromEvent} from "../../../lib/getAPI.js";
import {TasksDisplay} from "../tasksDisplay.jsx";

export function ViewTasksDialog({eventId, dialogOpen, setDialogOpen}) {
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (dialogOpen) {
            getTasks();
        }
    }, [dialogOpen]);

    async function getTasks() {
        const res = (await getTasksFromEvent(eventId));
        if (res.tasks) {
            setTasks(res.tasks);
        }
    }

    async function getTasksExcel() {
        const res = await fetch(`/api/admin/tasks/event/${eventId}/download`);

        const data = await res.blob();
        const url = window.URL.createObjectURL(new Blob([data]));
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = res.headers.get("Content-Disposition").split("=")[1];
        anchor.click();

        window.URL.revokeObjectURL(url);
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    return (
        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth={"md"} fullWidth={true}>
            <DialogTitle>Tasks</DialogTitle>
            <DialogContent>
                {tasks.length > 0 && <TasksDisplay tasks={tasks} />}
                {tasks.length === 0 && <Box sx={{textAlign: "center", mt: 2}}>
                    <Typography variant={"h5"}>No tasks</Typography>
                </Box>}
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between"}}>
                <ButtonGroup>
                    <Button variant={"contained"} onClick={getTasksExcel} sx={{display: (tasks.length > 0 ? "block" : "none")}}>Download all</Button>
                </ButtonGroup>
                <Button onClick={handleCloseDialog}>Exit</Button>
            </DialogActions>
        </Dialog>
    );
}