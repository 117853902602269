import {postJSON} from "../../../lib/postJSON.js";

async function vendorPostJSON(url, data) {
    if (url.startsWith("/")) {
        return await postJSON("/api/vendor" + url, data);
    } else {
        return await postJSON("/api/vendor/" + url, data);
    }
}

export async function postLogin(data) {
    return await vendorPostJSON('/login', data);
}