export async function fetchJSON(url, options) {
  const res = await fetch(url, options);
  try {
    return await res.json();
  } catch(e) {
    throw new Error(`Could not fetch ${url}, received ${res.status}`);
  }
  /*if (!res.ok) {
    throw new Error(`Could not fetch ${url}, received ${res.status}`);
  }
  return await res.json();*/
}
