import {Route, Routes} from "react-router-dom";
import {LoginPage} from "../pages/vendor/loginPage.jsx";
import React, {useEffect, useState} from "react";
import {getLogin} from "../pages/vendor/lib/getAPI.js";
import {VendorPage} from "../pages/vendor/vendorPage.jsx";
import {Typography} from "@mui/material";
import {TaskPage} from "../pages/vendor/taskPage.jsx";

export function VendorRouter() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        checkLogin();
    }, []);

    async function checkLogin() {
        const res = await getLogin();
        if (res) {
            setIsLoggedIn(res.isLoggedIn);
        }
        setLoading(false);
    }

    function loginCallback() {
        checkLogin();
    }

    return (
        <Routes>
            <Route path={"/login"} element={<LoginPage loginCallback={loginCallback}/>} />
            {isLoggedIn && (
                <>
                    <Route path={"/"} element={<VendorPage/>} />
                    <Route path={"/task/:taskId"} element={<TaskPage/>} />
                </>
            )}
            {!isLoggedIn && !loading && (<Route path={"*"} element={<Typography variant="h2" sx={{textAlign: "center", marginTop: "100px"}}>You are not logged in</Typography>} />)}
        </Routes>
    );
}