import {useContext, useEffect, useState} from "react";
import {getAllParticipants, getAvailableChats, getUser} from "../lib/getAPI.js";
import {Box, Grid, IconButton, Paper, TextField, Typography, useTheme} from "@mui/material";
import {EventContext} from "../../../routers/userRouter.jsx";
import {ChatWindow} from "./chatWindow.jsx";
import {ChatListItem} from "./components/chatListItem.jsx";
import AddCommentIcon from '@mui/icons-material/AddComment';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import {postCreateChat} from "../lib/postAPI.js";

export function ChatPage() {
    const eventId = useContext(EventContext);
    const theme = useTheme();
    const [chats, setChats] = useState([]);
    const [chatId, setChatId] = useState(null);
    const [searchFilter, setSearchFilter] = useState("");
    const [allParticipants, setAllParticipants] = useState([]);
    const [createChatWindow, setCreateChatWindow] = useState(false);
    const [createChatButtonColor, setCreateChatButtonColor] = useState("primary");

    useEffect(() => {
        if (eventId) {
            getChats();
        }
    }, [eventId]);

    useEffect(() => {
        setCreateChatButtonColor(createChatWindow ? "powerCustom" : "primary");
    }, [createChatWindow]);

    async function getChats() {
        const res = await getAvailableChats(eventId);
        if (res.chats) {
            setChats(res.chats.sort((a, b) => {
                if (a.last_chat_message_date && b.last_chat_message_date) {
                    return new Date(b.last_chat_message_date).getTime() - new Date(a.last_chat_message_date).getTime();
                }
                if (a.last_chat_message_date) {
                    return -1;
                }
                if (b.last_chat_message_date) {
                    return 1;
                }
                return 0;
            }));
        } else {
            console.log("Error getting chats");
        }
    }

    async function handleChatClick(chatId) {
        setChatId(chatId);
    }

    function closeOverlay() {
        getChats();
        setChatId(null);
    }

    async function createNewChatWindow() {
        if (createChatWindow) {
            setCreateChatWindow(false);
            setAllParticipants([]);
        } else {
            setCreateChatWindow(true);
            const resUserInfo = await getUser();
            if (resUserInfo.user) {
                const res = await getAllParticipants(eventId);
                if (res.participants) {
                    setAllParticipants(res.participants.filter(participant => participant.user_id !== resUserInfo.user.user_id));
                }
            }
        }
    }

    async function createChat(participantId) {
        console.log(participantId);
        const res = await postCreateChat(eventId, {userId: participantId});
        if (res.chatId) {
            setCreateChatWindow(false);
            setAllParticipants([]);
            setChatId(res.chatId);
        } else {
            console.log(res);
            alert("Error creating chat");
        }
    }

    return (
        <Box>
            <Box sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: theme.zIndex.tooltip,
                backgroundColor: "#fff",
                display: (chatId ? "block" : "none")
            }}><ChatWindow chatId={chatId} closeOverlay={closeOverlay}/></Box>
            {!chatId && chats && (
                <>
                    <Box sx={{width: "100%", overflow: "hidden", borderBottom: "1px #ccc solid"}}>
                        <Grid container>
                            <Grid item xs={10} sx={{padding: "14px"}}>
                                <TextField
                                    label="Search"
                                    size={"small"}
                                    type="text"
                                    fullWidth
                                    value={searchFilter}
                                    onChange={(e) => setSearchFilter(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton sx={{float: "right"}} color={createChatButtonColor} onClick={createNewChatWindow}>
                                    <AddCommentIcon sx={{fontSize: "40px", marginTop: "10px"}} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{padding: "10px 10px"}}>
                        {!createChatWindow && chats.filter(chat => chat.chat_name.toLowerCase().includes(searchFilter.toLowerCase())).map(chat => <ChatListItem key={chat.chat_id} chat={chat} handleChatClick={handleChatClick}/>)}
                        {createChatWindow && allParticipants.filter(participant => participant.user_firstname.toLowerCase().includes(searchFilter.toLowerCase()) || participant.user_lastname.toLowerCase().includes(searchFilter.toLowerCase())).sort((a, b) => {
                            if (a.event_role === "HOST" && b.event_role !== "HOST") {
                                return -1;
                            } else if (a.event_role !== "HOST" && b.event_role === "HOST") {
                                return 1;
                            } else {
                                return a.user_firstname.localeCompare(b.user_firstname);
                            }
                        }).map(participant =>
                            <Paper key={participant.user_id} sx={{marginTop: 1, marginBottom: 1, padding: 2, fontSize: "28px", border: "1px #ccc solid"}}>
                                <Grid container sx={{alignItems: "center"}}>
                                    <Grid item xs={10}>{`${participant.user_firstname} ${participant.user_lastname}`}{participant.event_role === "HOST" && (<Typography sx={{fontStyle: "italic", fontSize: "12px", padding: "0", margin: "0"}}>HOST</Typography>)}</Grid>
                                    <Grid item xs={2}>
                                        <IconButton sx={{float: "right"}} color="primary" onClick={()=>createChat(participant.user_id)}>
                                            <ChatBubbleIcon sx={{fontSize: "40px"}} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
}