import {Box, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import {dateFormat, dateFormatWithLinesSwapped} from "../../../../lib/dateFormat.js";

export function ChatListItem({chat, handleChatClick}) {
    const time = chat.last_chat_message_date ? (
        dateFormat(new Date(chat.last_chat_message_date)) !== dateFormat(new Date()) ? dateFormatWithLinesSwapped(new Date(chat.last_chat_message_date)).replaceAll("-", "/").substring(0, 5) : new Date(chat.last_chat_message_date).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }).substring(0, 5)
    ) : " - ";

    return (
        <>
            <Box className={"chatItem"} sx={{padding: "8px", backgroundColor: "#ddd", borderRadius: "4px", marginBottom: 1}} onClick={()=>handleChatClick(chat.chat_id)}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                    <Typography variant={"h5"} sx={{mr: 2, fontWeight: "bold"}}>{chat.chat_name}</Typography>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <PersonIcon fontSize={"small"}/>
                        <Typography variant={"span"}>{chat.chat_users_count}</Typography>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                    <Typography sx={{
                        color: "gray",
                        width: "70%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                    }}>{chat.last_chat_message || " "}</Typography>
                    <Typography sx={{color: "gray"}}>{time}</Typography>
                </Box>
            </Box>
        </>
    );
}