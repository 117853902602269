import {
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper, styled, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getVendorTasksFromEvent} from "../../../lib/getAPI.js";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const columns = [
    { id: 'comment', label: 'Comment', minWidth: 300 },
    { id: 'vendor', label: 'Vendor', minWidth: 170 },
    {
        id: 'group',
        label: 'Group',
        minWidth: 100
    }
];

export function VendorTasksDialog({eventId, dialogOpen, setDialogOpen}) {
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (dialogOpen) {
            getTasks();
        }
    }, [dialogOpen]);

    async function getTasks() {
        const res = (await getVendorTasksFromEvent(eventId));
        if (res.tasks) {
            setTasks(res.tasks);
        }
    }

    function handleCloseDialog() {
        setDialogOpen(false);
    }

    function TaskItemComponent({task}) {
        return (
            <Typography>{task.vendor_task_comment}</Typography>
        );
    }

    return (
        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth={"md"} fullWidth={true}>
            <DialogTitle>Tasks</DialogTitle>
            <DialogContent>
                {tasks.length > 0 && <Box>
                    <TableContainer component={Paper} sx={{maxHeight: 440}}>
                        <Table stickyHeader sx={{minWidth: 500}}>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                            sx={{backgroundColor: "#000", color: "#fff"}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasks.length > 0 && tasks.map(task => (
                                    <StyledTableRow key={task.vendor_task_id}>
                                        <TableCell component="th" scope="row">
                                            {task.vendor_task_comment}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {task.schedule_item_name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {task.group_id}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>}
                {tasks.length === 0 && <Box sx={{textAlign: "center", mt: 2}}>
                    <Typography variant={"h5"}>No tasks</Typography>
                </Box>}
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between"}}>
                <ButtonGroup>
                    <Button variant={"contained"} onClick={()=>{}} sx={{display: (tasks.length > 0 ? "block" : "none")}}>Download all</Button>
                </ButtonGroup>
                <Button onClick={handleCloseDialog}>Exit</Button>
            </DialogActions>
        </Dialog>
    );
}