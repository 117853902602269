export function dateValidation(date) {
    return date.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/) !== null && new Date(date).toString() !== "Invalid Date";
}
export function isSameDate(d1, d2) {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

function treatAsUTC(date) {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}
export function daysBetweenDates(startDate, endDate) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}