import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {LoginHeaderComponent} from "./components/loginHeaderComponent.jsx";
import {LoginFooterComponent} from "./components/loginFooterComponent.jsx";
import {loginCheck} from "../lib/loginCheck.js";
import {LoginStepOneComponent} from "./components/loginStepOneComponent";
import {LoginStepTwoComponent} from "./components/loginStepTwoComponent.jsx";

export function LoginPage({loginCallback}) {
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [uuid, setUuid] = useState("");
    const [verificationMethod, setVerificationMethod] = useState("");
    const [verificationError, setVerificationError] = useState("");
    const nav = useNavigate();

    useEffect(() => {
        loginCheck(setIsLoggedIn);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            nav("/user");
        }
    }, [isLoggedIn]);

    return (
        <div>
            <LoginHeaderComponent/>
            {uuid === "" ?
                <LoginStepOneComponent setUuid={setUuid} setVerificationMethod={setVerificationMethod} verificationError={verificationError} setVerificationError={setVerificationError} /> :
                <LoginStepTwoComponent uuid={uuid} setUuid={setUuid} verificationMethod={verificationMethod} setVerificationError={setVerificationError} loginCallback={loginCallback} />}
            <LoginFooterComponent/>
        </div>
    );
}