import {Box, Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {EventContext} from "../../../routers/userRouter.jsx";
import {getTasksFromEvent} from "../lib/getAPI.js";
import {useNavigate} from "react-router-dom";
import {CenteredLoading} from "../../../components/centeredLoading";

export function TasksPage() {
    const eventId = useContext(EventContext);
    const [tasks, setTasks] = useState([]);
    const [allCompleted, setAllCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        if (eventId) {
            getTasks();
        }
    }, [eventId]);

    async function getTasks() {
        setLoading(true);
        const res = (await getTasksFromEvent(eventId))?.tasks;
        if (res) {
            // check if all res are completed
            let allCompleted = true;
            for (let i = 0; i < res.length; i++) {
                if (!res[i].completed) {
                    allCompleted = false;
                }
            }
            if (!allCompleted) {
                setTasks(res);
                setAllCompleted(false);
            } else {
                setTasks(res);
                setAllCompleted(true);
            }
        }
        setLoading(false);
    }

    function handleTaskClick(id) {
        nav("/user/task/"+id);
    }

    return (
        <Box sx={{padding: 3}}>
            {loading ? <CenteredLoading /> : (tasks.map(t => (!t.completed &&
                <Card key={t.task_id} elevation={4} sx={{mb: 2}}>
                    <CardContent>
                        <Typography sx={{fontSize: 24}}>
                            {t.task_label}
                        </Typography>
                        <Typography sx={{fontSize: 14, color: "secondary.main"}}>
                            {t.task_description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="medium" variant={"contained"} onClick={()=>{handleTaskClick(t.task_id)}}>Do Task</Button>
                    </CardActions>
                </Card>)))}
            {allCompleted && <Typography sx={{fontSize: 24, width: "100%", textAlign: "center"}}>No available tasks</Typography>}
        </Box>
    );
}