export function ageFromDOB(a) {
    const today = new Date();
    const age = a.split(".");
    const year = parseInt(age[2]);
    const month = parseInt(age[1]);
    const day = parseInt(age[0]);

    let userAge;
    if (month <= (today.getMonth() + 1) && day <= today.getDate()) {
        userAge = today.getFullYear() - year;
    } else {
        userAge = today.getFullYear() - year - 1;
    }
    return userAge;
}