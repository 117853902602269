import {VendorHeaderComponent} from "./vendorHeaderComponent.jsx";
import {useNavigate, useParams} from "react-router-dom";
import {Box, Button, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {CenteredLoading} from "../../components/centeredLoading.jsx";

export function TaskPage() {
    const {taskId} = useParams();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [task, setTask] = useState(null);
    const [comment, setComment] = useState("");
    const nav = useNavigate();

    useEffect(() => {
        if (taskId) {
            getTask();
        }
    }, [taskId]);

    async function getTask() {
        setLoading(true);
        const res = await (await fetch(`/api/vendor/task/${taskId}`))?.json();
        if (res?.task) {
            setTask(res.task);
        }
        setLoading(false);
    }

    async function handleSend() {
        const res = await (await fetch(`/api/vendor/task/${taskId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                comment
            })
        }))?.json();
        if (res?.success) {
            setComment("");
            nav("/vendor");
        }
    }

    return (
        <>
            <VendorHeaderComponent backButton={true}/>
            {loading ? <CenteredLoading/> : (
                task ? (
                    <>
                        <Typography variant={"h4"} sx={{textAlign: "center", m: 1}}>{`Group ${task.group_id}`}</Typography>
                        <Box sx={{width: "100%", textAlign: "center", position: "absolute", top: "50%", transform: "translateY(-50%)"}}>
                            <TextField
                                label="Comment"
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                sx={{width: "80%", margin: "0 10vw"}}
                            />
                            <Button variant={"contained"} sx={{m: 5}} size={"large"} onClick={handleSend}>Send</Button>
                        </Box>
                    </>
                ) : (<Typography sx={{textAlign: "center", marginTop: "100px"}}>Task not found</Typography>)
            )}
        </>
    );
}