import {Box, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {getEventInfo} from "../lib/getAPI.js";
import {EventContext} from "../../../routers/userRouter.jsx";
import {CenteredLoading} from "../../../components/centeredLoading.jsx";
import {useNavigate} from "react-router-dom";

export function InfoPage() {
    const eventId = useContext(EventContext);
    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();

    useEffect(() => {
        if (window.location.pathname === "/user") { // THIS IS TEMP
            nav("/user/info");
        }
        if (eventId) {
            fetchInfo();
        }
    }, [eventId]);

    async function fetchInfo() {
        setLoading(true);
        if (eventId) {
            const resEvent = (await getEventInfo(eventId)).event;
            if (resEvent) {
                setInfo(resEvent.event_info);
            }
        }
        setLoading(false);
    }

    return (
        <div>
            <Box
                sx={{
                    m: 1
                }}
            >
                {(eventId && !loading) ?
                    (info ?
                        <Typography variant="p" whiteSpace="pre-line" fontSize={"1.3rem"}>{info}</Typography> :
                        <Typography variant="p" whiteSpace="pre-line" fontSize={"1.8rem"} sx={{textAlign: "center", display: "block"}}>There is currently no info for this event</Typography>
                    ) :
                    <CenteredLoading />}
            </Box>
        </div>
    );
}