import {fetchJSON} from "../../../lib/fetchJSON.js";

async function adminFetchJSON(url) {
    if (url.startsWith("/")) {
        return await fetchJSON("/api/admin" + url);
    } else {
        return await fetchJSON("/api/admin/" + url);
    }
}

export async function getEvents() {
    return await adminFetchJSON("/events");
}
export async function getEvent(id) {
    return await adminFetchJSON("/events/" + id);
}
export async function getEventAllInfo(id) {
    return await adminFetchJSON(`/events/${id}/allInfo`);
}
export async function getUsersFromEvent(id) {
    return await adminFetchJSON(`/events/${id}/users`);
}
export async function getTasksFromEvent(id) {
    return await adminFetchJSON(`/tasks/event/${id}`);
}
export async function getVendorTasksFromEvent(id) {
    return await adminFetchJSON(`/tasks/event/${id}/vendor`);
}

export async function getAllUsers() {
    return await adminFetchJSON("/users");
}

export async function getEventSchedule(id) {
    return await adminFetchJSON(`/events/${id}/schedule`);
}

export async function getEventScheduleItems(id) {
    return await adminFetchJSON(`/events/${id}/scheduleItems`);
}