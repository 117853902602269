import {fetchJSON} from "../../../lib/fetchJSON.js";

async function vendorFetchJSON(url) {
    if (url.startsWith("/")) {
        return await fetchJSON("/api/vendor" + url);
    } else {
        return await fetchJSON("/api/vendor/" + url);
    }
}
export async function getLogin() {
    return await vendorFetchJSON("/isLoggedIn");
}