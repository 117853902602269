import {useEffect, useState} from "react";
import {getEvents} from "../lib/getAPI.js";
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar, Typography
} from "@mui/material";
import {dateFormatWithLinesSwapped} from "../../../lib/dateFormat.js";
import {useNavigate} from "react-router-dom";
import {EventInfoDialog} from "./components/dialogs/eventInfoDialog.jsx";
import {CreateEventDialog} from "./components/dialogs/createEventDialog.jsx";

const columns = [
    {id: "event_name", align: "left", minWidth: 170, label: "Event Name" },
    {id: "event_start_date", align: "left", minWidth: 170, label: "Start date" },
    {id: "event_end_date", align: "left", minWidth: 170, label: "End date" },
    {id: "event_amount_of_users", align: "left", minWidth: 170, label: "Participants" }
];

export function ViewEventsPage() {
    const [events, setEvents] = useState([]);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        getAllEvents();
    }, []);

    async function getAllEvents() {
        const res = (await getEvents()).events;
        if (res) {
            const formattedEvents = res.map((event) => {
                return {
                    ...event,
                    event_start_date: dateFormatWithLinesSwapped(new Date(event.event_start_date)),
                    event_end_date: dateFormatWithLinesSwapped(new Date(event.event_end_date))
                }
            })
            setEvents(formattedEvents.sort((a, b) => b.event_id - a.event_id));
        }
    }

    function createNewEvent() {
        setInfoDialogOpen(true);
    }

    function handleEventClick(id) {
        nav("/admin/event/" + id);
    }

    return (
        <div>
            <Box sx={{ width: '70%', overflow: 'hidden', marginLeft: "15%", marginTop: "4vh", padding: "0 10px 10px 10px" }}>
                <Button variant={"contained"} sx={{mb: "20px"}} onClick={createNewEvent}>Create Event</Button>
                {events.length > 0 &&
                    <TableContainer sx={{maxHeight: 440}} component={Paper} elevation={4}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth, fontWeight: "bold"}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.event_id} onClick={()=>handleEventClick(row.event_id)}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {events.length === 0 && <Typography variant={"h5"} sx={{textAlign: "center"}}>No events found</Typography>}
            </Box>
            <CreateEventDialog dialogOpen={infoDialogOpen} setDialogOpen={setInfoDialogOpen} />
        </div>
    );
}