import {Box, Button, TextField, Typography} from "@mui/material";
import POWGiggle from "../../../../images/POW-02-Giggle-HN-v01_500x500.png";
import {postLogin} from "../../lib/postAPI.js";
import {useState} from "react";

export function LoginStepOneComponent({setUuid, setVerificationMethod, verificationError, setVerificationError}) {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    function handleEmailInputChange(e) {
        setEmail(e.target.value);
    }
    async function handleLoginClick(e) {
        e.preventDefault();
        const res = await postLogin({email: email});
        if (res.error) {
            setEmailError(res.error);
            setVerificationError("");
        } else if (res.uuid) {
            setVerificationMethod(res.method);
            setUuid(res.uuid);
        }
    }

    return (
        <Box
            onSubmit={handleLoginClick}
            component="form"
            sx={{
                '& > :not(style)': {m: 1},
            }}
            noValidate
            autoComplete="off"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="-5vh"
            minHeight="100vh"
            width="100vw"
            flexDirection="column"
        >
            <Box
                sx={{
                    maxWidth: "450px"
                }}
            >
                <Box
                    component="img"
                    src={POWGiggle}
                    sx={{
                        width: "60%",
                        marginBottom: "2em"
                    }}
                >
                </Box>
                <Typography
                    variant="h5"
                    sx={{
                        width: "40%",
                        display: "inline-block",
                        fontWeight: "bold",
                        fontStyle: "italic",
                        position: "relative",
                        top: "-3em",
                        left: "-1em"
                    }}
                >Something <Typography variant="span" sx={{color: "powerCustom.main"}}>powerful</Typography> is about
                    to start</Typography>
            </Box>
            <TextField onSubmit={handleLoginClick} onChange={handleEmailInputChange} error={emailError !== "" || verificationError !== ""} helperText={verificationError || emailError} id="outlined-basic" label="Email" variant="outlined" size="medium" sx={{width: '35ch'}}/>
            <Button onClick={handleLoginClick} variant="contained" disableElevation size="large" sx={{transform: "scale(1.2)"}}>Login</Button>
        </Box>
    );
}