import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import Logo from "../../images/power-logo-svg.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack.js";
import {useNavigate} from "react-router-dom";

export function VendorHeaderComponent({backButton}) {
    const nav = useNavigate();

    return (
        <>
            <AppBar position="fixed">
                <Toolbar sx={{height: "70px"}}>
                    <Box
                        component="img"
                        sx={{
                            height: "40px",
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)"
                        }}
                        align="center"
                        alt="Your logo."
                        src={Logo}
                    />
                    {backButton && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{padding: "20px", marginLeft: "-15px"}}
                            onClick={()=>nav(-1)}
                        >
                            <ArrowBackIcon fontSize="inherit" sx={{scale: "1.8", backgroundColor: "primary.dark", borderRadius: "5px", padding: "4px"}} />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <Toolbar sx={{height: "70px"}} />
        </>
    );
}