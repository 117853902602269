import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import {UserRouter} from "./routers/userRouter.jsx";
import {AdminRouter} from "./routers/adminRouter.jsx";
import React, {useEffect, useState} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {ScannerQRPage} from "./pages/scannerQRPage.jsx";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/de';
import {VendorRouter} from "./routers/vendorRouter.jsx";
import {Socket} from "./context/socket.jsx";

export const SocketContext = React.createContext(null);

function RedirectToUserFrontPage() {
    const nav = useNavigate();

    useEffect(() => {
        nav("/user");
    }, []);
}

export function Application() {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        setSocket(Socket());

        // Register service worker
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function() {
                navigator.serviceWorker.register(new URL('/sw.js', import.meta.url)).then(function(registration) {
                    console.log('ServiceWorker registration successful with scope: ', registration.scope);
                }, function(err) {
                    console.log('ServiceWorker registration failed: ', err);
                });
            });
        }
    }, []);

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2A2F32'
            },
            secondary: {
                main: '#8a8a8a',
            },
            powerCustom: {
                main: '#ec6608'
            },
            contrastThreshold: 3,
            tonalOffset: 0.2
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <CssBaseline/>
                <SocketContext.Provider value={socket}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={"/"} element={<RedirectToUserFrontPage/>}/>
                            <Route path={"/user/*"} element={<UserRouter/>}/>
                            <Route path={"/admin/*"} element={<AdminRouter/>}/>
                            <Route path={"/vendor/*"} element={<VendorRouter/>}/>
                            <Route path={"/scanner"} element={<ScannerQRPage/>}/>
                            <Route path={"/*"} element={<div>Page not found</div>}/>
                        </Routes>
                    </BrowserRouter>
                </SocketContext.Provider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}