import {createRef, useContext, useEffect, useState} from "react";
import {Box, Grid, IconButton, Paper, TextField, Toolbar} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {getChatMessages, getUser} from "../lib/getAPI.js";
import {MessageItemComponent} from "./components/messageItemComponent.jsx";
import {EventContext} from "../../../routers/userRouter.jsx";
import {SocketContext} from "../../../application.jsx";

const msgInput = createRef();
const scrollable = createRef();

export function Chat({chatId, imgBG}) {
    const eventId = useContext(EventContext);
    const socket = useContext(SocketContext);
    const [userInfo, setUserInfo] = useState(null);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [vvpHeight, setVvpHeight] = useState(window.visualViewport.height);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useEffect(() => {
        if (chatId) {
            getChatInfo();
        }
        return () => {
            socket.off("chatMessage", receiveMessage);
            socket.emit("routeDisconnect", {route: "chat", options: {chatId: chatId}});
        }
    }, [chatId]);

    useEffect(() => {
        visualViewport.addEventListener('resize', handleViewPortResize);
        const touchMoveOptions = { passive: false };
        window.addEventListener('touchmove', handleStopTouchMove, touchMoveOptions);

        return () => {
            visualViewport.removeEventListener('resize', handleViewPortResize);
            window.removeEventListener('touchmove', handleStopTouchMove, touchMoveOptions);
        }
    }, [])

    function handleViewPortResize() {
        setKeyboardHeight(window.innerHeight - window.visualViewport.height);
        setVvpHeight(window.visualViewport.height);
        setTimeout(scrollToBottom, 200);
    }
    function handleStopTouchMove(e) {
        const _keyboardHeight = window.innerHeight - window.visualViewport.height;
        if (_keyboardHeight > 0) {
            let parent = e.target;
            while (parent && parent.tagName !== "BODY") {
                if (!parent.className || !parent.className.includes) {
                    parent = parent.parentNode;
                    continue;
                }
                if (parent.className.includes("non-draggable-scrolling")) {
                    const el = parent;

                    const isOverflowing = el.clientHeight < el.scrollHeight;

                    if (!isOverflowing) {
                        e.preventDefault();
                    }

                    break;
                } else if (parent.className.includes("non-draggable")) {
                    e.preventDefault();
                    break;
                }
                parent = parent.parentNode;
            }
        }
    }

    async function getChatInfo() {
        const resUser = await getUser();
        if (resUser.user) {
            setUserInfo(resUser.user);
        }

        const resMessages = await getChatMessages(eventId, chatId, socket.id);
        if (resMessages?.chatMessages) {
            setMessages(resMessages.chatMessages);
        } else {
            console.log("Error getting messages");
        }

        // Check if existing listener
        if (socket._callbacks && socket._callbacks["chatMessage"] == undefined) {
            socket.on("chatMessage", receiveMessage);
        } else {
            console.log("Already has listener for chatMessage");
        }
        if (!socket.connected) {
            console.log("Socket not connected");
        }
        //socket.emit("routeConnect", {route: "chat", options: {chatId: chatId}});
    }

    function receiveMessage(msg) {
        setMessages((prev) => [msg, ...prev]);
    }

    function scrollToBottom() {
        scrollable.current.scrollTo({top: scrollable.current.scrollHeight, behavior: "smooth"});
    }

    function sendMessage() {
        socket.emit("chatMessage", {msg: message});

        setMessage("");
    }

    return (
        <Box sx={{"& *": {overscrollBehavior: "none"}}}>
            {/* CHAT CONTENT (MESSAGES) */}
            <Box className={"non-draggable-scrolling"} ref={scrollable} sx={{
                position: "absolute",
                bottom: "0",
                top: "0",
                left: "0",
                right: "0",
                display: "flex",
                flexDirection: "column-reverse",
                overflow: "scroll",
                height: (vvpHeight+"px"),
                backgroundImage: `url(${imgBG})`,
                backgroundSize: "100% auto",
                backgroundRepeat: "repeat-y"
            }}>
                {/* HIDDEN INPUT FOR POSITIONING */}
                <Paper sx={{width: "100vw", padding: "14px", mt: 1, opacity: "0"}} elevation={5} square>
                    <TextField
                        size={"small"}
                        type="text"
                        fullWidth
                        disabled
                    />
                </Paper>
                {messages.length > 0 && messages.flatMap((msg, index) => <MessageItemComponent key={msg.chat_message_id} msg={msg} prevMsg={index === messages.length-1 ? null : messages[index+1]} isOwnMessage={msg.user_email === userInfo.user_email}/>)}
                <Box sx={{width: "100vw", opacity: "0"}}>
                    <Toolbar sx={{height: "70px"}} />
                </Box>
            </Box>

            {/* BOTTOM BAR */}
            <Paper className={"non-draggable"} sx={{position: "fixed", bottom: (keyboardHeight+"px"), width: "100%", padding: "14px", overflow: "hidden"}} elevation={5} square>
                <Grid container>
                    <Grid item xs={11}>
                        <TextField
                            sx={{overscrollBehavior: "contain"}}
                            ref={msgInput}
                            label="Message"
                            size={"small"}
                            type="text"
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {if (e.key === "Enter") {sendMessage()}}}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton color="powerCustom" onClick={sendMessage}>
                            <SendIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}