import {useEffect, useState} from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import {FrontPage} from "../pages/admin/frontPage/frontPage.jsx";
import {ViewEventsPage} from "../pages/admin/viewEventsPage/viewEventsPage.jsx";
import {fetchJSON} from "../lib/fetchJSON.js";
import {LoginPage} from "../pages/admin/loginPage/loginPage.jsx";
import {AdminHeaderComponent} from "../pages/admin/components/adminHeaderComponent.jsx";
import {ViewEventPage} from "../pages/admin/viewEventsPage/viewEventPage.jsx";

export function AdminRouter() {
    const [loggedIn, setLoggedIn] = useState(false);
    const nav = useNavigate();

    useEffect(() => {
        checkLogin();
    }, []);

    function loginCallback() {
        checkLogin();
    }

    async function checkLogin() {
        const isLoggedIn = await fetchJSON("/api/admin/loggedIn");
        if (isLoggedIn) {
            const li = isLoggedIn["isLoggedIn"];
            setLoggedIn(isLoggedIn.isLoggedIn);
            const path = window.location.path;
            if (!li && path !== "/admin/login") {
                nav("/admin/login");
            }
        }
    }

    return (
        <>
            {loggedIn && <AdminHeaderComponent />}
            <Routes>
                {loggedIn && (<>
                    <Route path="/" element={<ViewEventsPage />}/>
                    <Route path="/events" element={<ViewEventsPage />} />
                    <Route path="/event/:event_id" element={<ViewEventPage />} />
                    <Route path={"/*"} element={"Page not found"} />
                </>)}
                <Route path="/login" element={<LoginPage loginCallback={loginCallback} />} />
            </Routes>
        </>
    );
}