import {fetchJSON} from "../../../lib/fetchJSON.js";

async function userFetchJSON(url, options) {
    if (url.startsWith("/")) {
        return await fetchJSON("/api/user" + url, options);
    } else {
        return await fetchJSON("/api/user/" + url, options);
    }
}

export async function getUser(setUser=null) {
    if (setUser) {
        setUser(await userFetchJSON("/"));
    } else {
        return await userFetchJSON("/");
    }
}
export async function getEvents() {
    return await userFetchJSON("/events");
}
export async function getEventIds() {
    return await userFetchJSON("/events/eventIds");
}
export async function getEventInfo(event_id) {
    return await userFetchJSON("/events/"+event_id);
}
export async function getEventSchedule(event_id) {
    return await userFetchJSON("/schedule/event/"+event_id)
}
export async function getGroup(event_id) {
    return await userFetchJSON(`/group/event/${event_id}`);
}
export async function getAllParticipants(event_id) {
    return await userFetchJSON(`/events/${event_id}/participants`);
}
export async function getTasksFromEvent(event_id) {
    return await userFetchJSON(`/events/${event_id}/tasks`);
}
export async function getTaskWithItems(task_id) {
    return await userFetchJSON(`/tasks/${task_id}`);
}
export async function getChatMessages(eventId, chatId, socketId) {
    return await userFetchJSON(`/events/${eventId}/chats/${chatId}/messages`, {headers: {"x-socket-id": socketId}});
}
export async function getAvailableChats(eventId) {
    return await userFetchJSON(`/events/${eventId}/chats`);
}
export async function getChatMembers(eventId, chatId) {
    return await userFetchJSON(`/events/${eventId}/chats/${chatId}/members`);
}