import {postJSON} from "../../../lib/postJSON.js";

async function userPostJSON(url, data) {
    if (url.startsWith("/")) {
        return await postJSON("/api/user" + url, data);
    } else {
        return await postJSON("/api/user/" + url, data);
    }
}

export async function postLogin(data) {
    return await userPostJSON('/login', data);
}
export async function postLoginVerify(data) {
    return await userPostJSON('/login/verify', data);
}
export async function postCompleteTask(id, data) {
    return await userPostJSON(`/tasks/${id}`, data);
}
export async function postCreateChat(eventId, data) {
    return await userPostJSON(`/events/${eventId}/chats`, data);
}