import {postJSON} from "../../../lib/postJSON.js";
import {postFile} from "../../../lib/postFile.js";

async function adminPostJSON(url, data, method="POST") {
    if (url.startsWith("/")) {
        return await postJSON("/api/admin" + url, data, method);
    } else {
        return await postJSON("/api/admin/" + url, data, method);
    }
}
async function adminPostFile(url, data, method="POST") {
    if (url.startsWith("/")) {
        return await postFile("/api/admin" + url, data);
    } else {
        return await postFile("/api/admin/" + url, data);
    }
}

export async function patchUpdateEventInfo(id, data) {
    return await adminPostJSON(`/events/${id}`, data, "PATCH");
}
export async function postCheckin(id, userId, data) {
    return await adminPostJSON(`/events/${id}/checkin/${userId}`, data);
}
export async function postSendInviteMessages(id, data) {
    return await adminPostJSON(`/events/${id}/sendInvite`, data);
}
export async function postSendCustomMessage(data) {
    return await adminPostJSON(`/sms`, data);
}
export async function postCreateTask(id, data) {
    return await adminPostJSON(`/events/${id}/task`, data);
}
export async function postCreateEvent(data) {
    return await adminPostJSON(`/events/create`, data);
}
export async function postUploadUsersFile(id, file) {
    return await adminPostFile(`/events/${id}/upload/users`, file);
}
export async function postUploadScheduleFile(id, file) {
    return await adminPostFile(`/events/${id}/upload/schedule`, file);
}
export async function patchUserGroup(id, user_id, data) {
    return await adminPostJSON(`/events/${id}/users/${user_id}/group`, data, "PATCH");
}
export async function postCreateVendorKey(id, data) {
    return await adminPostJSON(`/events/${id}/vendorKey`, data);
}
export async function postChangeUserStatus(id, data) {
    return await adminPostJSON(`/users/${id}/eventInvite`, data);
}
export async function postAddUserToEvent(id, data) {
    return await adminPostJSON(`/events/${id}/users`, data);
}
export async function postCreateUser(data) {
    return await adminPostJSON(`/users`, data);
}
export async function postMakeUserHost(eventId, data) {
    return await adminPostJSON(`/events/${eventId}/users/host`, data);
}