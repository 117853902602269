import {useEffect, useState} from "react";
import {Box, Button, TextField, Typography} from "@mui/material";
import {postLoginVerify} from "../../lib/postAPI.js";
import {useNavigate} from "react-router-dom";

export function LoginStepTwoComponent({uuid, setUuid, verificationMethod, setVerificationError, loginCallback}) {
    const [verificationText, setVerificationText] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const nav = useNavigate();

    useEffect(() => {
        //
    }, [uuid]);
    useEffect(() => {
        switch(verificationMethod) {
            case "email":
                setVerificationText("A verification code has been sent to your email");
                break;
            case "sms":
                setVerificationText("A verification code has been sent to your phone number");
                break;
            case "":
                setVerificationText("");
                break;
            default:
                setVerificationText("Error: Unknown verification method");
                break;
        }
    }, [verificationMethod]);

    function handleVerificationCodeChange(e) {
        setVerificationCode(e.target.value);
    }
    async function handleLoginClick(e) {
        e.preventDefault();
        const res = await postLoginVerify({uuid: uuid, code: verificationCode});
        if (res.error) {
            setVerificationError(res.error);
            setUuid("");
        } else if (res.message === "verified") {
            loginCallback();
            nav("/user");
        }
    }

    return (
        <Box
            onSubmit={handleLoginClick}
            component="form"
            sx={{
                '& > :not(style)': {m: 1.5},
            }}
            noValidate
            autoComplete="off"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="-5vh"
            minHeight="100vh"
            width="100vw"
            flexDirection="column"
            textAlign="center"
        >
            <Typography variant="h5" sx={{width: '23ch'}}>{verificationText}</Typography>
            <TextField autoFocus onSubmit={handleLoginClick} onChange={handleVerificationCodeChange} id="outlined-basic" label="Verification code" variant="outlined" size="medium" sx={{width: '35ch'}}/>
            <Button onClick={handleLoginClick} variant="contained" disableElevation size="large" sx={{transform: "scale(1.2)"}}>Login</Button>
        </Box>
    );
}